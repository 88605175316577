import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

export const tokenInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  let headers = req.headers;

  if (req.headers.get('Content-Type') !== 'multipart/form-data') {
    headers = headers.set('Content-Type', 'application/json');
  } else {
    headers = headers.delete('Content-Type');
  }

  if (authService.isAuthenticated()) {
    headers = headers.set('x-auth-token', authService.token);
  }

  const authReq = req.clone({ headers });
  return next(authReq);
};
