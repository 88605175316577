import { NgClass } from '@angular/common';
import { Component, WritableSignal, inject, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map } from 'rxjs';

interface Breadcrumb {
  label: string;
  url: UrlSegment[];
}

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [NgClass, TranslateModule],
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  breadcrumbs$: WritableSignal<Breadcrumb[]> = signal<Breadcrumb[]>([]);

  constructor() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.buildBreadCrumb(this.activatedRoute.root))
      )
      .subscribe((breadcrumbs) => {
        this.breadcrumbs$.set(breadcrumbs);
      });
  }

  private buildBreadCrumb(route: ActivatedRoute, breadcrumb: Breadcrumb[] = []): Breadcrumb[] {
    const data: Partial<{ breadcrumb: { label: string } }> | undefined = route.routeConfig?.data;
    if (breadcrumb.length === 0) {
      breadcrumb.push({
        label: 'breadcrumb.home',
        url: [new UrlSegment('/', {})],
      });
    }

    let label = data?.breadcrumb?.label ?? '';
    breadcrumb.push({
      label,
      url: [...breadcrumb[breadcrumb.length - 1].url, ...route.snapshot.url],
    });

    if (route.firstChild) {
      this.buildBreadCrumb(route.firstChild, breadcrumb);
    }
    breadcrumb = this.removeEmptyBreadcrumbs(breadcrumb);
    if (breadcrumb.length === 1 && breadcrumb[0].url[0].path === '/') {
      return [];
    }
    return breadcrumb;
  }

  private removeEmptyBreadcrumbs(breadcrumbs: Breadcrumb[]): Breadcrumb[] {
    return breadcrumbs.filter(({ label }) => label);
  }

  redirectTo(url?: UrlSegment[]): void {
    if (url) {
      this.router.navigate(
        url.map(({ path }) => path),
        { queryParamsHandling: 'preserve' }
      );
    }
  }
}
