import { Routes } from '@angular/router';
import { articoliRoutes } from '@routes/articoli.routes';
import { categorieRoutes } from '@routes/categorie.routes';
import { errorRoutes } from '@routes/error.routes';
import { homeRoutes } from '@routes/home.routes';
import { settingsRoutes } from '@routes/settings.routes';

export const routes: Routes = [
  ...homeRoutes,
  ...settingsRoutes,
  ...categorieRoutes,
  ...articoliRoutes,
  ...errorRoutes,
];
