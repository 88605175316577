<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-center py-5">
      <form [formGroup]="searchForm" class="text-left">
        <div>
          <h2>
            Ricerca Ruolo
          </h2>
        </div>
        <div class="d-flex gap-1 flex-wrap gap-md-0 flex-md-nowrap justify-content-around">
          <mat-form-field>
            <mat-label>Codice</mat-label>
            <input matInput formControlName="code" placeholder="Codice">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Descrizione</mat-label>
            <input matInput formControlName="description" placeholder="Descrizione">
          </mat-form-field>
        </div>
        <div class="text-end">
          <button class="" (click)="search()" mat-raised-button color="primary">FILTRA</button>
          &nbsp;
          &nbsp;
          <button class="" (click)="clear()" mat-raised-button color="primary">RESET</button>
        </div>
      </form>
    </div>
    <div class="col-md-12 py-5">
      <div class="d-flex py-3 justify-content-between align-items-center">
        <h2>Gestione Ruoli</h2>
        <button mat-fab color="primary" aria-label="Inserisci nuovo ruolo" (click)="new()" matTooltipPosition="below"
          matTooltip="Nuovo ruolo">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <ngx-dynamic-table [columnDefinitions]="columnDefinitions" [showFirstLastButtons]="false" [pageSizeOptions]="[]"
        (pageChange)="search(true)" (pageSizeChange)="search()" (sortChange)="search()"
        (onInit)="search()"></ngx-dynamic-table>
    </div>
  </div>
</div>

<ng-template #detailsTemplate let-element>
  <button class="primary-99" mat-mini-fab color="primary-99" (click)="edit(element)"
    class="mat-elevation-z0"><mat-icon>edit</mat-icon></button>
</ng-template>

<ng-template #detailsModal>
  @if(editItem && editItem.id) {<h2 mat-dialog-title>Modifica Ruolo</h2>}
  @if(editItem && !editItem.id){<h2 mat-dialog-title>Nuovo Ruolo</h2>}
  <mat-dialog-content class="mat-typography">
    <form class="details-form" [formGroup]="editItemForm">
      <mat-form-field class="half">
        <mat-label>Codice</mat-label>
        <input matInput formControlName="code">
      </mat-form-field>
      <mat-form-field class="half">
        <mat-label>Descrizione</mat-label>
        <input matInput formControlName="description">
      </mat-form-field>
      <!-- <mat-form-field class="full">
        <mat-label>Permessi</mat-label>
        <mat-select multiple formControlName="grants">
          @for(grant of grantsDataSource; track grant){
          <mat-option [value]="grant">
            {{ grant }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field> -->
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button (click)="save()">Salva</button>
    <button mat-raised-button (click)="delete()">Elimina</button>
    <button mat-raised-button mat-dialog-close>Chiudi</button>
  </mat-dialog-actions>
</ng-template>
