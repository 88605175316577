import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
})
export class NonAutorizzatoComponent {
  authService = inject(AuthService);
  private router = inject(Router);
  constructor() {
    if (this.authService.isAuthenticated()) this.router.navigate(['/']);
  }

  goHome() {
    this.router.navigate(['/']);
  }
}
