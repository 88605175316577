import { ApplicationConfig, LOCALE_ID, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/*
 * Import the routes from the app.routes file
 **/
import { DATE_PIPE_DEFAULT_OPTIONS, DatePipe } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { localization } from '@config/localization.config';
import { mainConfig } from '@config/main.config';
import { httpInterceptorFn } from '@services/http-interceptor.service';
import { tokenInterceptor } from '@services/token-interceptor.service';
import { NgxUploadModule } from '@sinapsys/ngx-upload';
import { routes } from './app.routes';
import { QuillModule } from 'ngx-quill';

/*
 * Import the HttpClient and TranslateLoader classes
 **/
interface I18NConfig {
  defaultLanguage: string;
  loader: {
    provide: typeof TranslateLoader;
    useFactory: (http: HttpClient) => TranslateHttpLoader;
    deps: (typeof HttpClient)[];
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/public/i18n/', '.json');
}

export const I18N_CONFIG: I18NConfig = {
  defaultLanguage: localization.defaultLanguage,
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
};

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

/*
 * Define the application configuration
 **/
export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, inMemoryScrollingFeature),
    provideHttpClient(withInterceptors([tokenInterceptor, httpInterceptorFn])),
    provideAnimationsAsync(),
    importProvidersFrom(TranslateModule.forRoot(I18N_CONFIG)),
    importProvidersFrom(
      NgxUploadModule.forRoot({
        mediaBaseUrl: mainConfig.mediaBaseUrl,
      }),
      QuillModule.forRoot({
        suppressGlobalRegisterWarning: true
      }),
    ),
    DatePipe,
    { provide: 'COMMON_APP_CONFIG', useValue: mainConfig },
    {
      provide: LOCALE_ID,
      useValue: mainConfig.localization.defaultLocale,
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: {
        dateFormat: mainConfig.dateFormat,
      },
    },
  ],
};
