import { Categoria } from './categoria.model';
import { FileSys } from './fileSystem.model';
import { Provincia } from './provincia.model';
import { Regione } from './regione.model';
import { User } from './user.model';

export class Articolo {
    id!: number;
    letture!: string;
    confirm!: string;
    regione!: Regione;
    provincia!: Provincia;
    titolo!: string;
    categoria!: Categoria;
    testo!: string;
    autore_str!: string;
    autore!: User;
    perma_autore!: string;
    permalink!: string;
    data!: number;
    approvato!: string;
    commenti!: number;
    keywords!: string;
    fileSystem!: FileSys;
    evidenza!: string;
    evidenza_regione!: string;
    email!: string;
    rubrica!: string;
    ip!: string;
    secondopiano_regione!: string;
    email_esterno!: string;
    esterno!: number;
    cognome_esterno!: string;
    nome_esterno!: string;
    shared!: number;

  constructor(record?: any) {
    record = record || {};
    Object.assign(this, record);
    this.categoria = record.categoria ? new Categoria(record.categoria) : new Categoria();
    this.provincia = record.provincia ? new Provincia(record.provincia) : new Provincia();
    this.regione = record.regione ? new Regione(record.regione) : new Regione();
    this.autore = record.autore ? new User(record.autore) : new User();
  }

   toString(): string {
    return this.titolo;
  }
}
