<div class="container-fluid ps-0 mat-app-background">
  <div class="row flex-wrap">
    <div [ngClass]="!(this.isMobile$ | async) ? 'col-auto' : 'col-12 pe-0'">
      @if(authService.isAuthenticated()) { <app-sidebar></app-sidebar> }
    </div>
    <div [ngClass]="!(this.isMobile$ | async) ? 'col' : 'col-12'">
      <app-breadcrumb></app-breadcrumb>
      <router-outlet></router-outlet>
      <app-back-to-top></app-back-to-top>
    </div>
  </div>
</div>

<div #loading id="loading" class="hidden">
  <app-loader></app-loader>
</div>
