import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2, TemplateRef, ViewChild, ViewEncapsulation, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmbedPDF } from '@config/embed-pdf';
import { EmbedVideo } from '@config/embed-video';
import { mainConfig } from '@config/main.config';
import { join } from '@fireflysemantics/join';
import { Articolo } from '@models/articolo.model';
import { Categoria } from '@models/categoria.model';
import { FileSys } from '@models/fileSystem.model';
import { Provincia } from '@models/provincia.model';
import { Regione } from '@models/regione.model';
import { User } from '@models/user.model';
import { AIService } from '@services/ai.service';
import { ArticoliService } from '@services/articoli.service';
import { AuthService } from '@services/auth.service';
import { CategorieService } from '@services/categorie.service';
import { ProvinceService } from '@services/province.service';
import { RegioniService } from '@services/regioni.service';
import { UploadsService } from '@services/uploads.service';
import { UsersService } from '@services/users.service';
import { UtilsService } from '@services/utils.service';
import { PageRequest } from '@sinapsys/ngx-crud';
import { ColumnDefinition, NgxDynamicTableComponent, NgxDynamicTableModule } from '@sinapsys/ngx-dynamic-table';
import moment from 'moment';
import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import { FileUploadModule } from 'primeng/fileupload';
import { Observable, Subscription, debounceTime, switchMap } from 'rxjs';
import { COMMON_APP_CONFIG } from 'src/app/core/tokens/common-config.token';

@Component({
  selector: 'app-articoli',
  standalone: true,
  imports: [NgxDynamicTableModule, mainConfig.material, mainConfig.formModules, AsyncPipe, FileUploadModule, FormsModule, QuillModule],
  templateUrl: './articoli.component.html',
  styleUrls: ['./articoli.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArticoliComponent implements OnInit {
  @ViewChild(NgxDynamicTableComponent, { static: false }) table!: NgxDynamicTableComponent<Articolo>;
  @ViewChild('detailsModal', { static: true }) detailsModal!: TemplateRef<unknown>;
  @ViewChild('detailsTemplate', { static: true }) detailsTemplate!: TemplateRef<unknown>;

  columnDefinitions: ColumnDefinition<Articolo>[] = [];
  searchSubscription?: Subscription;
  searchForm: FormGroup;
  editItemForm: FormGroup;
  editItem?: Articolo;
  dialogRef?: MatDialogRef<unknown>;
  autoriDataSource!: Observable<User[]>;
  autoriDetailDataSource!: Observable<User[]>;
  categorieDataSource!: Observable<Categoria[]>;
  categorieDetailDataSource!: Observable<Categoria[]>;
  regioniDataSource: Regione[] = [];
  provinceDataSource: Provincia[] = [];
  provinceFilteredDataSource: Provincia[] = [];

  @ViewChild('quillEditor') quillEditor!: QuillEditorComponent;
  testo: string = '';

  editorModules: any;

  embedVideo: any = EmbedVideo;
  embedPDF: any = EmbedPDF;
  constructor(
    public utilsService: UtilsService,
    public datePipe: DatePipe,
    private articoliService: ArticoliService,
    private dialog: MatDialog,
    private categorieService: CategorieService,
    public authService: AuthService,
    private usersService: UsersService,
    private regioniService: RegioniService,
    private provincieService: ProvinceService,
    private snackBar: MatSnackBar,
    private uploadService: UploadsService,
    private aiService: AIService,
    private renderer: Renderer2,
    @Inject(COMMON_APP_CONFIG) private mainConfig: Required<{ api: { apiBaseUrl: string } }>
  ) {
    this.searchForm = new FormGroup({
      id: new FormControl<number | undefined>(undefined),
      titolo: new FormControl<string | undefined>(undefined),
      autore: new FormControl<User | undefined>(undefined),
      categoria: new FormControl<Categoria | undefined>(undefined),
      data_da: new FormControl<number | undefined>(undefined),
      data_a: new FormControl<number | undefined>(undefined),
    });

    this.editItemForm = new FormGroup({
      id: new FormControl<number | undefined>(undefined),
      regione: new FormControl<Regione | undefined>(undefined),
      provincia: new FormControl<Provincia | undefined>(undefined),
      titolo: new FormControl<string | undefined>(undefined),
      categoria: new FormControl<Categoria | undefined>(undefined),
      testo: new FormControl<string | undefined>(undefined),
      // descrizione: new FormControl<string | undefined>(undefined),
      autore: new FormControl<User | undefined>(undefined),
      data: new FormControl<Date | undefined>(undefined),
      approvato: new FormControl<number | undefined>(undefined),
      keywords: new FormControl<string | undefined>(undefined),
      img: new FormControl<string | undefined>(undefined),
      evidenza: new FormControl<number | undefined>(undefined),
      evidenza_regione: new FormControl<number | undefined>(undefined),
      permalink: new FormControl<string | undefined>(undefined),
      fileSystem: new FormControl<FileSys | undefined>(undefined),
      chat_con_editor: new FormControl<string | undefined>(undefined),
    });
    this.editorModules = {
      toolbar: {
        container: [
          // Dropdown per lo stile di intestazione (h1, h2, h3, etc.)
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

          // Pulsanti per il grassetto, corsivo, sottolineato e barrato
          ['bold', 'italic', 'underline', 'strike'],

          // Dropdown per cambiare il colore del testo e dello sfondo
          [{ 'color': [] }, { 'background': [] }],

          // Pulsante per inserire un link e altri elementi
          ['link', 'image', 'video', 'blockquote', 'code-block'],

          // Dropdown per liste ordinate e non ordinate
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],

          // Indentazione
          [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent

          // Direzione del testo (utile per le lingue RTL)
          [{ 'direction': 'rtl' }],

          // Dropdown per cambiare il font e la dimensione del testo
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }], // dimensioni

          // Controlli di allineamento del testo
          [{ 'align': [] }],

          // Pulsante per rimuovere la formattazione
          ['clean'],
          [{ 'insertEmbed': 'insertEmbed' }, { 'insertPDF': 'insertPDF' }],
        ],
        handlers: {
          insertEmbed: () => this.showEmbedPopup(),
          insertPDF: () => this.showPDFPopup(),
        }
      }
    }
  };

  showEmbedPopup(): void {
    let embed = prompt('Inserisci il codice incorpora / embed del video:');
    if (embed) {
      const range = this.quillEditor.quillEditor.getSelection();
      if (range) {
        const embedValue = this.utilsService.parseIframeAttributes(embed);
        this.quillEditor.quillEditor.insertEmbed(range.index, 'embed-video', embedValue);
        this.editItemForm.controls['testo'].setValue(this.quillEditor.quillEditor.root.innerHTML);
      }
    }
  }

  showPDFPopup(): void {
    // Creazione dinamica dell'input file
    const fileInput = this.renderer.createElement('input');
    this.renderer.setAttribute(fileInput, 'type', 'file');
    this.renderer.setAttribute(fileInput, 'accept', 'application/pdf');
    this.renderer.setStyle(fileInput, 'display', 'none');

    // Aggiunge l'input file al DOM
    this.renderer.appendChild(document.body, fileInput);

    // Listener per la selezione del file
    fileInput.addEventListener('change', (event: any) => {
      const file: File = event.target.files[0];
      if (file && file.type === 'application/pdf') {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const base64String = (e.target as FileReader).result as string;
          const nodeData = { href: base64String, name: file.name };
          const selection = this.quillEditor.quillEditor.getSelection();
          console.log('selection', selection);
          const text = this.quillEditor.quillEditor.getText(selection?.index as number, selection?.length as number);
          if (text.length > 0) {
            nodeData.name = text;
          }
          this.quillEditor.quillEditor.insertEmbed(selection?.index as number, 'embed-pdf', nodeData);
          this.editItemForm.controls['testo'].setValue(this.quillEditor.quillEditor.root.innerHTML);
        };
        fileReader.readAsDataURL(file);
      } else {
        alert('Per favore, carica solo file PDF.');
      }
      // Rimuove l'input file dal DOM dopo l'uso
      this.renderer.removeChild(document.body, fileInput);
    });

    // Simula il click sull'input file per aprire il popup
    fileInput.click();
  }

  ngOnInit(): void {
    this.columnDefinitions = [
      { id: 'cognome_esterno', header: 'Esterno', sortable: false, parser: (a) => (a != null && a.trim() != '' ? 'Si' : 'No'), width: '100px' },
      { id: 'id', header: 'ID', sortable: true, width: '100px' },
      { id: 'evidenza', header: 'PPN', sortable: false, parser: (v) => (v === '1' ? 'Si' : 'No'), width: '100px' },
      { id: 'titolo', header: 'Titolo', sortable: true },
      { id: 'autore', header: 'Autore', parser: (v) => (v ? v.blogger : ''), sortable: true, width: '150px' },
      { id: 'data', header: 'Data pubblicazione', parser: (v) => moment.unix(v).format('DD/MM/YYYY HH:mm'), sortable: true, width: '200px' },
      { id: 'approvato', header: 'Pubblicato', parser: (v) => (v === '1' ? 'Si' : 'No'), sortable: true, width: '150px' },
      { id: 'categoria', header: 'Categoria', parser: (v) => (v ? v.nome : ''), sortable: true, width: '200px' },
      { id: '_', header: 'Dettagli', template: this.detailsTemplate, width: '100px' },
    ];

    // this.categorieService.search({}, new PageRequest(0, -1)).subscribe((res) => {
    //   this.categorieDataSource = res.items.map((r: any) => new Categoria(r));
    //   // alfabetical order
    //   this.categorieDataSource.sort((a, b) => a.nome.localeCompare(b.nome));
    // });

    this.regioniService.search({}, new PageRequest(0, -1)).subscribe((res) => {
      this.regioniDataSource = res.items.map((r: any) => new Regione(r));
      // alfabetical order
      this.regioniDataSource.sort((a, b) => a.nomeregione.localeCompare(b.nomeregione));
    });

    this.provincieService.search({ $with: ['regione'] }, new PageRequest(0, -1)).subscribe((res) => {
      this.provinceDataSource = res.items.map((r: any) => new Provincia(r));
      this.provinceFilteredDataSource = this.provinceDataSource;
    });

    this.autoriDataSource = this.searchForm.controls['autore'].valueChanges.pipe(
      debounceTime(500),
      switchMap((searchQuery) => {
        if (typeof searchQuery === 'string' && searchQuery) return this.usersService.getAutocomplete(searchQuery);
        else return this.autoriDataSource;
      })
    );

    this.autoriDetailDataSource = this.editItemForm.controls['autore'].valueChanges.pipe(
      debounceTime(500),
      switchMap((searchQuery) => {
        if (typeof searchQuery === 'string' && searchQuery) return this.usersService.getAutocomplete(searchQuery);
        else return this.autoriDetailDataSource;
      })
    );

    this.categorieDetailDataSource = this.editItemForm.controls['categoria'].valueChanges.pipe(
      debounceTime(500),
      switchMap((searchQuery) => {
        if (typeof searchQuery === 'string' && searchQuery) return this.categorieService.getAutocomplete(searchQuery);
        else return this.categorieDetailDataSource;
      })
    );

    this.categorieDataSource = this.searchForm.controls['categoria'].valueChanges.pipe(
      debounceTime(500),
      switchMap((searchQuery) => {
        if (typeof searchQuery === 'string' && searchQuery) return this.categorieService.getAutocomplete(searchQuery);
        else return this.categorieDataSource;
      })
    );
  }


  search(keepPage?: boolean) {
    let raw = this.searchForm.getRawValue();
    if (!keepPage) this.table.paginator.pageIndex = 0;
    if (raw.titolo) raw.titolo = { $like: raw.titolo };
    if (raw.categoria) raw.categoria = { id: raw.categoria.id };
    if (raw.autore) raw.autore = { id: raw.autore.id };
    let pageRequest: PageRequest = new PageRequest(
      this.table.paginator.pageIndex,
      this.table.paginator.pageSize,
      this.table.sort.direction,
      this.table.sort.active
    );

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    this.searchSubscription = this.articoliService.search(raw, pageRequest).subscribe({
      next: (res) => {
        res.items = res.items.map((item) => new Articolo(item));
        this.table.populate(res);
      },
      error: (err) => {
        this.utilsService.defaultHttpErrorHandler(err);
      },
    });
  }

  clear() {
    this.searchForm.reset();
    this.table.sort.active = 'id';
    this.table.sort.direction = 'desc';
    this.search();
  }

  new() {
    // reset form
    this.selectedFile = null;
    this.imageName.set('');
    this.fileSize.set(0);
    this.imagePreview.set('');
    this.editItem = undefined;
    this.testo = '';
    this.edit(new Articolo());
  }

  async edit(item: Articolo) {
    this.editItemForm.reset();
    this.editItemForm.controls['permalink'].disable();
    console.log("item", item)
    if (item.id != null) {
      this.editItemForm.patchValue(item);
      this.testo = item.testo;
      item = await this.articoliService
        .search({ id: item.id, $with: ['regione', 'provincia', 'fileSystem'] }, new PageRequest(0, 1))
        .toPromise()
        .then((res: any) => res.items[0]);
      if (item.fileSystem) {
        let imgUrl = `${join(this.utilsService.generateFilePath(item.fileSystem.disk_name))}`;
        console.log('img relative url', imgUrl);
        this.utilsService.downloadImage(imgUrl).subscribe((res) => {
          // convert res (base64 img) to buffer
          this.selectedFile = new File([res], item.fileSystem.file_name, { type: 'image/jpeg' });
          this.imagePreview.set(res as string);
          this.fileSize.set(item.fileSystem.file_size);
          this.imageName.set(item.fileSystem.file_name);
        });
      }
      if (item.autore?.id != undefined) this.editItemForm.controls['autore'].setValue(new User({ id: item.autore?.id, blogger: item.autore?.blogger }));
      else {
        this.editItemForm.controls['autore'].setValue(null);
      }
      if (item.data) this.editItemForm.controls['data'].setValue(moment.unix(item.data).format('YYYY-MM-DDTHH:mm'));
      if (item.regione?.id != undefined) {
        this.provinceFilteredDataSource = this.provinceDataSource.filter((p) => p.regione?.id === item.regione?.id);
        this.editItemForm.controls['regione'].setValue(new Regione({ id: item.regione?.id, nomeregione: item.regione?.nomeregione }));
      } 
      else {
        this.editItemForm.controls['regione'].setValue(null);
      }
      if (item.provincia?.id != undefined) this.editItemForm.controls['provincia'].setValue(new Provincia({ id: item.provincia?.id, nomeprovincia: item.provincia?.nomeprovincia }));
      else {
        this.editItemForm.controls['provincia'].setValue(null);
      }
      if (item.fileSystem?.id != undefined) this.editItemForm.controls['fileSystem'].setValue(new FileSys(item.fileSystem));
      else {
        this.editItemForm.controls['fileSystem'].setValue(null);
      }
      if (item.categoria?.id != undefined) this.editItemForm.controls['categoria'].setValue(new Categoria({ id: item.categoria?.id, nome: item.categoria?.nome }));
      else {
        this.editItemForm.controls['categoria'].setValue(null);
      }
      this.testo = item.testo;
    } else {
      item.data = moment().unix();
      this.editItemForm.controls['data'].setValue(moment().format('YYYY-MM-DDTHH:mm'));
      item.evidenza = '0';
      this.editItemForm.controls['evidenza'].setValue('0');
      item.evidenza_regione = '0';
      this.editItemForm.controls['evidenza_regione'].setValue('0');
      item.approvato = '0';
      this.editItemForm.controls['approvato'].setValue('0');
      item.autore = this.authService.user as User;
      this.editItemForm.controls['autore'].setValue({ id: item.autore.id, blogger: item.autore.blogger });
    }

    this.editItem = item;
    this.dialogRef = this.dialog.open(this.detailsModal, { maxWidth: '99%' });
  }

  async save() {
    let raw = this.editItemForm.getRawValue();
    delete raw.chat_con_editor;
    console.log('id', raw.id);
    if (!raw.id) {
      let articoloByTitolo: any = await this.articoliService.search({ titolo: raw.titolo }, new PageRequest(0, 1)).toPromise();
      console.log('articoloByTitolo', articoloByTitolo);
      if (articoloByTitolo.items.length > 0) {
        this.utilsService.notificationsService.showNotification('', 'Esiste già un articolo con lo stesso titolo', 'error');
        return;
      }
    }
    if (raw.data) raw.data = moment(raw.data, 'YYYY-MM-DDTHH:mm').unix();
    else raw.data = moment().unix();
    if (raw.autore?.id) raw.autore = { id: raw.autore.id };
    else {
      raw.autore = { id: 0 };
    }
    if (raw.categoria?.id) raw.categoria = { id: raw.categoria.id };
    else {
      raw.categoria = { id: 0 };
    }
    if (raw.regione?.id) raw.regione = { id: raw.regione.id };
    else delete raw.regione;
    if (raw.provincia?.id) raw.provincia = { id: raw.provincia.id };
    else delete raw.provincia;
    if (raw.fileSystem?.id) raw.fileSystem = { id: raw.fileSystem.id };
    else delete raw.fileSystem;
    if (raw.evidenza == null) raw.evidenza = '0';
    if (raw.evidenza_regione == null) raw.evidenza_regione = '0';

    if (raw.approvato == '1') {
      console.log('raw', raw);
      if (raw.titolo.trim() === '' ||
        raw.permalink.trim() === '' ||
        raw.testo.trim() === '' ||
        !raw.fileSystem?.id ||
        !raw.autore?.id ||
        !raw.data ||
        !raw.categoria?.id ||
        !raw.regione?.id ||
        !raw.provincia?.id ||
        raw.keywords == null ||
        raw.keywords.trim() === '' ||
        raw.evidenza == null) {
        this.utilsService.notificationsService.showNotification('', 'Per pubblicare un articolo è necessario compilare tutti i campi', 'error');
        return;
      }
    }
    // raw.testo = this.testo;
    console.log('raw testo', raw.testo);
    this.articoliService.save(JSON.parse(JSON.stringify(raw))).subscribe({
      next: (res) => {
        this.utilsService.notificationsService.showNotification('', 'Articolo salvato con successo', 'success');
        this.editItem = new Articolo(res);
        this.editItemForm.patchValue(this.editItem);
        this.testo = this.editItem.testo;
        this.editItemForm.controls['autore'].setValue(new User({ id: this.editItem.autore?.id, blogger: this.editItem.autore?.blogger }));
        this.editItemForm.controls['data'].setValue(moment.unix(this.editItem.data).format("YYYY-MM-DDTHH:mm"));
        this.search(true);
        this.dialogRef?.close();
        this.edit(this.editItem)
      },
      error: (err) => {
        this.utilsService.defaultHttpErrorHandler(err);
      },
    });
  }

  delete() {
    if (!this.authService.user?.can('owners')) return;
    if (!confirm('Sei sicuro di voler eliminare questo articolo?')) return;
    let raw = this.editItemForm.getRawValue();
    this.articoliService.delete(raw.id).subscribe({
      next: (res) => {
        this.utilsService.notificationsService.showNotification('', 'Articolo eliminato con successo', 'success');
        this.search(true);
        this.dialogRef?.close();
      },
      error: (err) => {
        this.utilsService.defaultHttpErrorHandler(err);
      },
    });
  }
  onChangeRegione(event: any) {
    console.log(event.value);
    this.provinceFilteredDataSource = this.provinceDataSource.filter((p) => p.regione?.id === event.value.id);
  }

  onInputTitle(event: any) {
    if (this.editItemForm.controls['id'].value != null) return;
    let permalink = this.utilsService.generatePermalink(event.target.value);
    this.editItemForm.controls['permalink'].setValue(permalink);
  }

  // file methods
  imageName = signal('');
  fileSize = signal(0);
  uploadProgress = signal(0);
  imagePreview = signal('');
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  selectedFile: File | null = null;
  uploadSuccess: boolean = false;
  uploadError: boolean = false;

  // Handler for file input change
  onFileChange(event: any): void {
    const file = event.target.files[0] as File | null;
    this.uploadFile(file);
  }

  // Handler for file drop
  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] as File | null;
    this.uploadFile(file);
  }

  // Prevent default dragover behavior
  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  // Method to handle file upload
  uploadFile(file: File | null): void {
    if (file && file.type.startsWith('image/')) {
      this.selectedFile = file;
      console.log('file', file);
      this.fileSize.set(Math.round(file.size / 1024)); // Set file size in KB
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64Img = e.target?.result as string;
        this.imagePreview.set(base64Img); // Set image preview URL
        // convert to buffer
        let diskName = this.utilsService.generateRandomString(22);
        let fileSystem = new FileSys({
          attachment_id: this.editItemForm.controls['id'].value,
          disk_name: diskName,
          file_name: file.name,
          file_size: file.size,
          content_type: file.type,
          attachment_type: `Infooggi\\Articoli\\Models\\Article`,
          field: 'imgUp',
          base64Img: base64Img,
          file_path: this.utilsService.generateFilePath(diskName),
        });
        this.uploadService.uploadFile(fileSystem).subscribe(
          (res) => {
            if (this.editItem) {
              this.editItem.fileSystem = new FileSys(res);
              this.editItemForm.controls['fileSystem'].setValue(res);
              this.utilsService.notificationsService.showNotification('', 'Immagine caricata con successo', 'success');
            }
          },
          (err) => {
            this.snackBar.open("Errore durante il caricamento dell'immagine", 'Chiudi', {
              duration: 3000,
              panelClass: 'error',
            });
            this.uploadSuccess = false;
            this.uploadError = true;
          }
        );
      };
      reader.readAsDataURL(file);
      this.uploadSuccess = true;
      this.uploadError = false;
      this.imageName.set(file.name); // Set image name
    } else {
      this.uploadSuccess = false;
      this.uploadError = true;
      this.snackBar.open('Puoi inserire solo immagini', 'Chiudi', {
        duration: 3000,
        panelClass: 'error',
      });
    }
  }

  // Method to remove the uploaded image
  removeImage(): void {
    this.selectedFile = null;
    this.imageName.set('');
    this.fileSize.set(0);
    this.imagePreview.set('');
    this.uploadSuccess = false;
    this.uploadError = false;
    this.uploadProgress.set(0);
  }

  // AI
  async generaTesto() {
    let value = await this.utilsService.prompt('Inserisci informazioni a corredo per migliorare la generazione del testo', "riscrivi questo testo con un taglio giornalistico...oppure...basandoti sul titolo, scrivi un testo con un taglio giornalistico di 2000 caratteri, ecc");
    if (value != null) {
      let prompt = "";
      if (this.editItemForm.controls['testo'].value != null && this.editItemForm.controls['testo'].value.length > 0) {
        prompt += "Dato questo testo: \n\n" + this.editItemForm.controls['testo'].value + "\n\n, modificalo seguendo le seguenti linee guida: ";
      } else {
        prompt += "Dato il titolo dell'articolo giornalistico: \n\n" + this.editItemForm.controls['titolo'].value + "\n\n, scrivi un testo seguendo le seguenti linee guida: ";
      }
      prompt += value;
      prompt += "\nIl testo prodotto deve essere in formato HTML, non rispondere con nient'altro che il testo richiesto modificato.\nNon inserire alcun titolo nel testo prodotto.\nNon inserire la parola HTML o simboli strani nel testo, puoi utilizzare solo i tag <b>, <br>, <i>.";
      prompt += "\nRispondi con un JSON contenente la tua risposta. Il JSON deve avere una chiave 'result' che contiene il testo formattato in HTML.";
      let res: any = await this.aiService.callAI(prompt).toPromise();
      console.log('res', res);
      this.editItemForm.controls['testo'].setValue(res);
    }
  }
  async miglioraFormattazione() {
    if (this.editItemForm.controls['testo'].value != null && this.editItemForm.controls['testo'].value.length > 0) {
      let prompt = "";

      console.log("testoooossss", this.editItemForm.controls['testo'].value)

      prompt += "Dato questo testo: \n\n" + this.editItemForm.controls['testo'].value + "\n\n, migliorane la formattazione aggiungendo gli adeguati a capo e spaziatura. Inoltre aggiungi il grassetto e il corsivo dove necessario.";

      prompt += `\nIl testo prodotto deve essere in formato HTML (pertanto valuta l'aggiunta di appositi tag HTML per costruire una formattazione efficace), non rispondere con nient'altro che il testo richiesto modificato.\nNon inserire alcun titolo nel testo prodotto.\nNon inserire la parola HTML o simboli strani nel testo, puoi utilizzare solo i tag <b>, <br>, <i>. Attenzione, metti anche i punti a capo suddividendo il testo in paragrafi (utilizzando i tag <br> tra una frase e l'altra).
      Attenzione: è importante che il testo sia ben formattato e leggibile, quindi inserisci i tag HTML necessari per una corretta formattazione del testo.
      Se devi suddividere il testo in più paragrafi, voglio che ci sia uno spazio vuoto tra un paragrafo e l'altro, pertanto inserisci un tag <br> tra un paragrafo e l'altro.`;
      prompt += "\nRispondi con un JSON contenente la tua risposta. Il JSON deve avere una chiave 'result' che contiene il testo formattato in HTML.";
      let res: any = await this.aiService.callAI(prompt).toPromise();
      console.log('res', res);
      this.editItemForm.controls['testo'].setValue(res.result);
    }
  }

  async generaKeywords() {
    if (this.editItemForm.controls['testo'].value != null && this.editItemForm.controls['testo'].value.length > 0) {
      let prompt = "";
      prompt += "Dato questo testo: \n\n" + this.editItemForm.controls['testo'].value + "\n\n, genera 5 keywords efficaci per la SEO.\nOgni keyword deve essere separata da uno spazio non deve contenere caratteri speciali.\nLe keywords composte da più parole devono essere tutte attaccate e separate da un trattino, es: valle-daosta o francesco-totti.\nNon concludere il tuo messaggio con un punto o nessun altro carattere.\nNella tua risposta non scrivere nient'altro al di fuori delle keywords.";
      prompt += "\nRispondi con un JSON contenente le keywords generate. Il JSON deve avere una chiave 'result' che contiene la stringa con le keywords separate da uno spazio. ATTENZIONE: è importante che le keywords siano separate da uno spazio e non contengano caratteri speciali.";
      let res: any = await this.aiService.callAI(prompt).toPromise();
      console.log('res', res);
      this.editItemForm.controls['keywords'].setValue(res.result);
    }
  }

  publishSocial() {
    this.aiService.socialPublish(`https://www.infooggi.it/articolo/${this.editItemForm.controls['permalink'].value}/${this.editItemForm.controls['id'].value}`, this.editItemForm.controls['id'].value).subscribe(res => {
      this.utilsService.notificationsService.showNotification('', 'Articolo condiviso con successo!', 'success');
      this.editItem = new Articolo(res);
      this.editItemForm.patchValue(this.editItem);
      this.editItemForm.controls['autore'].setValue(new User({ id: this.editItem.autore?.id, blogger: this.editItem.autore?.blogger }));
      this.editItemForm.controls['data'].setValue(moment.unix(this.editItem.data).format("YYYY-MM-DDTHH:mm"));
      this.search(true);
      this.dialogRef?.close();
      this.edit(this.editItem)

    }, err => { });
  }

  visualizzaSulSito(cache: boolean = false) {
    let url = `https://www.infooggi.it/articolo/${this.editItemForm.controls['permalink'].value}/${this.editItemForm.controls['id'].value}`;
    if (cache) {
      url += '/f';
    }
    window.open(url, '_blank');
  }
  onContentChanged(event: any) {
    console.log('event', event);
    this.testo = event.html;
  }

  submitEditorChat() {
    let text = `
      Dato il testo che ti fornirò riguardante un articolo giornalistico, apporta le seguenti correttive che ti fornirò in seguito.
      Ecco il testo iniziale:
      <testo>${this.editItemForm.controls['testo'].value}</testo>
      Ecco le migliorie che devi apportare:
      <migliorie>${this.editItemForm.controls['chat_con_editor'].value}.</migliorie>
      Rispondi solo ed esclusivamente con il testo che hai modificato, non inserire nient'altro.`;
      text += "\nRispondi con un JSON contenente la tua risposta. Il JSON deve avere una chiave 'result' che contiene la risposta. ATTENZIONE, la risposta deve sempre essere in html";

    this.aiService.callAI(text).subscribe({
      next: (res: any) => {
        this.editItemForm.controls['testo'].setValue(res.result);
        // this.miglioraFormattazione();
      },
      error: (err) => {
        this.utilsService.defaultHttpErrorHandler(err);
      },
    });
  }
}
