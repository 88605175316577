import { Regione } from "./regione.model";

export class FileSys {
  id: number;
  file_name!: string;
  disk_name!: string;
  content_type!: string;
  attachment_id!: string;
  attachment_type!: string;
  file_size!: number;
  file_path!: string;
  base64Img!: string;
  field!: string;

  constructor(record?: any) {
    this.id = record?.id;
    record = record || {};
    Object.assign(this, record);
  }

   toString(): string {
    return this.disk_name;
  }
}
