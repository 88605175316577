<div class="container mt-5 p-0">
  <div class="row justify-content-center text-center">
    <div class="col-12">
      <h2>Non hai i permessi per visualizzare questa pagina</h2>
    </div>
    <div class="col-12">
      <a (click)="goHome()" class="btn btn-primary" mat-raised-button>
        Torna alla Home
      </a>
    </div>
    <div style="margin: auto; width: 40%;" class="p-12">
      <div>
        <p>Oppure usa il pulsante seguente per effettuare l'accesso</p>
        <button (click)="authService.login()" color="primary" mat-raised-button>
          Login
        </button>
      </div>
    </div>
  </div>
</div>
