import { Inject, Injectable } from '@angular/core';
import { Model } from '@sinapsys/ngx-crud';
import { NgxUploadFile, NgxUploadService } from '@sinapsys/ngx-upload';
import { Observable, distinctUntilChanged, fromEvent, map, shareReplay, startWith } from 'rxjs';
import { COMMON_APP_CONFIG } from '../tokens/common-config.token';
import { NotificationsService } from './notifications.service';
import { join } from '@fireflysemantics/join';
import { PromptDialogComponent } from '@modules/prompt-dialog/prompt-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  readonly isScrolled$: Observable<boolean>;
  isMobile$: Observable<boolean>;

  constructor(
    private uploadService: NgxUploadService,
    public notificationsService: NotificationsService,
    private dialog: MatDialog,
    @Inject(COMMON_APP_CONFIG)
    private mainConfig: Required<{
      layout: {
        mobileBreakpoint: number;
        desktopScrollBreakpoint: number;
      };
    }>
  ) {
    const mediaQuery = window.matchMedia(`(max-width: ${this.mainConfig.layout.mobileBreakpoint}px)`);
    this.isMobile$ = fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
      startWith(mediaQuery),
      map((list: MediaQueryList) => list.matches),
      distinctUntilChanged(),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );
    this.isScrolled$ = fromEvent(document, 'scroll').pipe(
      startWith(false),
      map(() => window.scrollY > this.mainConfig.layout.desktopScrollBreakpoint),
      distinctUntilChanged()
    );
  }

  defaultHttpErrorHandler(error: any): void {
    if (error.error == undefined || error.error == null || error.error == '') {
      this.notificationsService.showNotification('Error', error.message, 'error');
    } else {
      if (error?.error?.message) {
        this.notificationsService.showNotification('Error', error.error.message, 'error');
        return;
      }
      this.notificationsService.showNotification('Error', error.error, 'error');
    }
  }

  allowed(_grant: string) {
    return true;
  }

  compare(a: any, b: any) {
    return a === b;
  }

  saveAs(blob: Blob, fileName: string) {
    let url = URL.createObjectURL(blob);
    return this.download(fileName, url);
  }

  download(fileName: string, url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  copyToClipboard(data: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = data;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    navigator.clipboard.writeText(data);
    document.body.removeChild(selBox);
  }

  getHourDiff(pStartHour: string, pEndHour: string) {
    let res: boolean;
    let aTmp = [];
    aTmp = pStartHour.split(':');
    let nStartMin = Number(aTmp[0]) * 60 + Number(aTmp[1]);
    aTmp = pEndHour.split(':');
    let nEndMin = Number(aTmp[0]) * 60 + Number(aTmp[1]);
    if (nStartMin < nEndMin) {
      res = true;
    } else {
      res = false;
    }
    return res;
  }

  displayEntity(m: Model) {
    return m ? m.toString() : '';
  }

  getFormattedDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  compareById(a: any, b: any) {
    return a?.id === b?.id;
  }

  compareByValue(a: any, b: any) {
    return a === b;
  }

  compareByCodice(a: any, b: any) {
    return a?.codice === b?.codice;
  }

  xmlToJson(xml: any) {
    let obj: any = {};
    if (xml.nodeType == 1) {
      obj = this.processAttributes(xml, obj);
    } else if (xml.nodeType == 3) {
      obj = xml.nodeValue;
    }
    if (xml.hasChildNodes()) {
      obj = this.processChildNodes(xml, obj);
    }
    return obj;
  }

  processAttributes(xml: any, obj: any) {
    if (xml.attributes.length > 0) {
      obj['@attributes'] = {};
      for (let j = 0; j < xml.attributes.length; j++) {
        const attribute = xml.attributes.item(j);
        obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
      }
    }
    return obj;
  }

  processChildNodes(xml: any, obj: any) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      let item = xml.childNodes.item(i);
      item = item == null ? '' : item;
      let nodeName = item.nodeName;
      if (nodeName == '#text') nodeName = 'text';
      obj = this.processNodeName(obj, nodeName, item);
    }
    return obj;
  }

  processNodeName(obj: any, nodeName: string, item: any) {
    if (typeof obj[nodeName] == 'undefined') {
      obj[nodeName] = this.xmlToJson(item);
    } else {
      obj = this.handleExistingNodeName(obj, nodeName, item);
    }
    return obj;
  }

  handleExistingNodeName(obj: any, nodeName: string, item: any) {
    if (typeof obj[nodeName].push == 'undefined') {
      const old = obj[nodeName];
      obj[nodeName] = [];
      obj[nodeName].push(old);
    }
    obj[nodeName].push(this.xmlToJson(item));
    return obj;
  }

  filter(arr: any[], val: string, fields: string[]): any[] {
    return arr.filter((item) => {
      let res = false;
      fields.forEach((field) => {
        if (item[field] && item[field].toString().toLowerCase().indexOf(val.toLowerCase()) > -1) {
          res = true;
        }
      });
      return res;
    });
  }

  getFile(doc: NgxUploadFile) {
    this.uploadService.download(doc).subscribe({
      next: (res) => {
        let fileName: string = doc.originalFilename;
        let data = new Blob([res], { type: doc.contentType });
        this.saveAs(data, fileName);
      },
      error: (err) => {
        this.defaultHttpErrorHandler(err);
      },
    });
  }

  cleanString(str: string) {
    return str.replace(/[^a-zA-Z0-9]/g, '_').toLocaleLowerCase();
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  generateFilePath(fileName: string): string {
    // Verifica che il nome del file sia almeno di 12 caratteri (8 per il prefisso e 4 per l'estensione)
    if (fileName.length < 12) {
      throw new Error('Il nome del file è troppo corto.');
    }

    // Estrai il prefisso del file
    const prefix = fileName.substring(0, 9);

    // Suddividi il prefisso in 4 segmenti da 3 caratteri ciascuno
    const segments = prefix.match(/.{1,3}/g);

    // Se ci sono meno di 4 segmenti, lancia un errore
    if (segments === null || segments.length !== 3) {
      throw new Error('Il prefisso del file non è valido.');
    }
    console.log('segments', segments);
    // Costruisci il percorso finale
    const path = join('images', 'uploads', 'public', ...segments, fileName);

    return path;
  }
  // download image and convert to base64 (ambiente angular)
  downloadImage(url: string): Observable<string> {
    let baseUrl = 'https://cdn.infooggi.it';
    return new Observable<string>((observer) => {
      const xhr = new XMLHttpRequest();
      console.log('img absolute url', join(baseUrl, url));
      xhr.open('GET', join(baseUrl, url), true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onload = () => {
          observer.next(reader.result as string);
          observer.complete();
        };
        reader.readAsDataURL(xhr.response);
      };
      // xhr.onreadystatechange = (): any => {
      //   const status = xhr.status;
      //   console.log(status);
      //   if (status == 200) {
      //     // url is local file path
      //     baseUrl = join('C:/', 'Users', 'zimo', 'Documents', 'Progetti', 'infooggi_3_0_be', 'BE', 'src', 'lib', 'uploads');
      //     const reader = new FileReader();
      //     reader.onload = () => {
      //       observer.next(reader.result as string);
      //       observer.complete();
      //     };
      //     console.log('IMAGE LOCAL URL', join(baseUrl, url));
      //     fetch(join(baseUrl, url))
      //       .then((res) => res.blob())
      //       .then((blob) => reader.readAsDataURL(blob));
      //   }
      // };
      xhr.send();
    });
  }

  base64ToBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64.split(',')[1]);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
  // generate a random sting like this 66893aef22bb6854580249
  generateRandomString(length: number): string {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  // generate permalink from title
  generatePermalink(title: string): string {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }
  prompt(title: string, defaultValue?: string): Promise<string | null> {
    return new Promise((resolve, _reject) => {
      const dialogRef = this.dialog.open(PromptDialogComponent, {
        width: '500px',
      });
      dialogRef.componentInstance.text = title;
      if (defaultValue)
        dialogRef.componentInstance.defaultValue = defaultValue;
      dialogRef.componentInstance.afterSave.subscribe((res: any) => {
        resolve(res);
      });
    });
  }

  parseIframeAttributes(iframeString: string) {
    const attributes: any = {};
    const regex = /(\w+)=["']([^"']*)["']/g;
    let match;

    // Usa l'espressione regolare per trovare tutti gli attributi nell'iframe
    while ((match = regex.exec(iframeString)) !== null) {
      const attributeName = match[1];
      const attributeValue = match[2];
      attributes[attributeName] = attributeValue;
    }

    return attributes;
  }
}
