import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NgxUploadFile, NgxUploadService } from '@sinapsys/ngx-upload';
import { Observable } from 'rxjs';
import { COMMON_APP_CONFIG } from '../tokens/common-config.token';
import { NgxCrudService } from '@sinapsys/ngx-crud';
import { FileSys } from '@models/fileSystem.model';

export interface UploadFileQueue {
  filename: string;
  data: FormData;
  progress: number;
  attempts: number;
  options: unknown;
}

@Injectable({
  providedIn: 'root',
})
export class UploadsService extends NgxCrudService<FileSys, number> {

  constructor(@Inject(COMMON_APP_CONFIG) private mainConfig: Required<{ api: { apiBaseUrl: string } }>) {
    super({ apiBaseUrl: '' });
    this._baseUrl = this.mainConfig.api.apiBaseUrl + 'media/';
  }

  uploadFile(file: FileSys): Observable<FileSys> {
    return this.http.post<FileSys>(this._baseUrl + 'upload', file);
  }
}
