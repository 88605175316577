import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject, signal } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { User } from '../models/user.model';
import { NotificationsService } from './notifications.service';
import { COMMON_APP_CONFIG } from '../tokens/common-config.token';

export class Navigation {
  id!: string;
  title!: string;
  url!: string;
  icon!: string;

  constructor(record?: any) {
    record = record || {};
    Object.assign(this, record);
  }
}

export class Module {
  id!: string;
  title!: string;
  url!: string;
  icon!: string;
  navigations!: Navigation[];

  constructor(record?: any) {
    record = record || {};
    Object.assign(this, record);
    this.navigations = record.navigations ? record.navigations.map((n: unknown) => new Navigation(n)) : [];
  }
}

export interface AuthResponse {
  token: string;
  user: User;
  message?: string;
  modules: Module[];
  grants: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _baseUrl: string;
  private _token?: string;
  public _user?: User;
  public modules = signal<Module[]>([]);

  constructor(
    private http: HttpClient,
    private router: Router,
    private notificationsService: NotificationsService,
    @Inject(COMMON_APP_CONFIG) private mainConfig: Required<{ api: { authUrl: string } }>
  ) {
    this._baseUrl = this.mainConfig.api.authUrl;
  }

  public get token() {
    return this._token || localStorage.getItem('auth-token') || '';
  }

  public set token(token: string) {
    localStorage.setItem('auth-token', token);
    this._token = token;
  }

  public login() {
    window.location.href = this._baseUrl;
  }

  public getMe(): Observable<AuthResponse> {
    return this.http.get<AuthResponse>(this._baseUrl + 'me', { withCredentials: true, responseType: 'json' }).pipe(
      map((res) => {
        res.user = this._user = new User(res.user);
        return res;
      })
    );
  }

  public get user(): User | undefined {
    if (this._user) return this._user;
    return undefined;
  }

  public logout() {
    localStorage.clear();
    window.location.href = '/';
  }

  public isAuthenticated() {
    return this.token !== '';
  }

  public checkAuth() {
    if (location.href.includes('?')) {
      let url = new URL(location.href);
      if (url.searchParams.has('token') && url.searchParams.get('token')) {
        const tkn = url.searchParams.get('token') || '';
        const next = url.searchParams.get('next') || '';
        if (next) sessionStorage.setItem('force-redirect', next);
        this.token = tkn;
        location.href = '/';
      }
    }
    if (!this.token) return;
    return this.getMe()
      .pipe(
        map((res) => {
          let next = sessionStorage.getItem('force-redirect') || location.pathname;
          sessionStorage.removeItem('force-redirect');
          this.router.navigate([next]);
          this.modules.update(() => res.modules);
          return res;
        })
      )
      .pipe(
        catchError((err) => {
          const errorMessage = err.error?.message || err.error?.error || err || 'An error occurred on me';
          this.notificationsService.showNotification('Error', errorMessage, 'error');
          return of(null);
        })
      );
  }

  public getGrants(): Observable<string[]> {
    return this.http.get<string[]>(this._baseUrl + 'grants', { withCredentials: true, responseType: 'json' });
  }

  can(s: string): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this.token) return resolve(false);
      if (!this._user) {
        let interval = setInterval(() => {
          if (this._user) {
            clearInterval(interval);
            resolve(this.can(s));
          }
          if (!this._token) {
            clearInterval(interval);
            resolve(false);
          }
        }, 100);
        return;
      }
      if (this._user.role.code === s) return resolve(true);
      else return resolve(false);
      // const [m, n, g] = s.split(':');
      // let grants: string[] = Array.from(new Set(this._user.roles.reduce((a: string[], r: Role) => [...a, ...[r.code]], [])));
      // if (n === '*') {
      //   let ms = grants.map((g) => g.split(':')[0]);
      //   if (ms.indexOf(m) > -1) return resolve(true);
      // }
      // if (g === '*') {
      //   let mn = grants.map((g) => [g.split(':')[0], g.split(':')[1]].join(':'));
      //   if (mn.indexOf([m, n].join(':')) > -1) return resolve(true);
      // }
      // if (grants.indexOf(`*:*:*`) > -1) return resolve(true);
      // if (grants.indexOf(`${m}:*:*`) > -1) return resolve(true);
      // if (grants.indexOf(`${m}:${n}:*`) > -1) return resolve(true);
      // if (grants.indexOf(s) > -1) return resolve(true);
      // return resolve(false);
    });
  }


}

export const canActivate: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const notificationsService = inject(NotificationsService);
  const router = inject(Router);

  const auth = await authService.isAuthenticated();
  if (auth) return true;
  else {
    router.navigate(['/unauthorized']);
    notificationsService.showNotification('Errore', 'Non sei autorizzato a visualizzare questa pagina', 'error');
    return false;
  }
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => canActivate(route, state);
