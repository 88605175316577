<div class="row" style="overflow-x: hidden;">
  <div class="full" style="padding: 45px; border-bottom: 5px dotted rgb(1, 97, 1);">
    {{ text }}
  </div>
  <mat-form-field class="full mt-2" style="padding-left: 20px; padding-right: 20px;">
    <textarea matInput [rows]="10" [(ngModel)]="returnValue"></textarea>
  </mat-form-field>
</div>
<mat-dialog-actions class="align-items-right">
  <button mat-raised-button (click)="save()">
    {{ saveLabel }}
  </button>
  <button mat-raised-button (click)="cancel()">{{ cancelLabel }}</button>
</mat-dialog-actions>