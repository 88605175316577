@if(authService.isAuthenticated()) {
<ng-container>
  <div class="container-fluid">
    <div class="row vh-full align-items-center">
      <div class="col-12 text-center mt-auto">
        <img src="/public/images/splash.png" alt="Splash" class="img-splash mb-4" />
        <h1 class="mb-5">Benvenuto {{ authService._user?.blogger }}</h1>
      </div>
      <div class="d-flex flex-wrap justify-content-center align-items-center w-100">
        @for (module of authService.modules(); track module.title) {
        <div class="d-flex flex-column align-items-center">
          <p class="fs-4">{{ module.title }}</p>
          <div class="d-flex flex-wrap justify-content-center gap-3 box">
            @for(nav of module.navigations; track nav.url) {
            <a mat-raised-button color="primary"
              class="module d-flex flex-column gap-1 align-items-center justify-content-center"
              style="text-align: center" [routerLink]="nav.url">
              <mat-icon class="m-0">{{ nav.icon }}</mat-icon>
              <span>{{ nav.title }}</span>
            </a>
            }
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</ng-container>
} @else if(!authService.isAuthenticated()) {
<ng-container>
  <div class="container-fluid vh-full">
    <div class="row h-100">
      <div class="col-12 d-flex">
        <div class="m-auto text-center">
          <img src="/public/images/splash.png" alt="Splash" class="img-splash mb-5" />
          <br />
          <a mat-raised-button color="primary" [href]="loginUrl()" rel="noreferrer">
            Accedi
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
}
