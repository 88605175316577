<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-center py-5">
      <form [formGroup]="searchForm" class="text-left">
        <div>
          <h2>Ricerca Articoli</h2>
        </div>
        <div class="d-flex gap-1 flex-wrap gap-md-0 flex-md-nowrap justify-content-around">
          <mat-form-field>
            <mat-label>ID</mat-label>
            <input matInput formControlName="id" placeholder="ID" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Titolo</mat-label>
            <input matInput formControlName="titolo" placeholder="Titolo" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Categoria</mat-label>
            <input type="text" placeholder="Categoria" aria-label="Number" matInput formControlName="categoria" [matAutocomplete]="categoriaAuto" />
            <mat-autocomplete #categoriaAuto="matAutocomplete">
              @for (categoria of categorieDataSource | async; track categoria) {
              <mat-option [value]="categoria">{{ categoria.nome }}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Autore</mat-label>
            <input type="text" placeholder="Autore" aria-label="Number" matInput formControlName="autore" [matAutocomplete]="autoreAuto" />
            <mat-autocomplete #autoreAuto="matAutocomplete">
              @for (autore of autoriDataSource | async; track autore) {
              <mat-option [value]="autore">{{ autore }}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="text-end">
          <button class="" (click)="search()" mat-raised-button color="primary">FILTRA</button>
          &nbsp; &nbsp;
          <button class="" (click)="clear()" mat-raised-button color="primary">RESET</button>
        </div>
      </form>
    </div>
    <div class="col-md-12 py-5">
      <div class="d-flex py-3 justify-content-between align-items-center">
        <h2>Gestione Articoli</h2>
        <button mat-fab color="primary" aria-label="Inserisci nuovo articolo" (click)="new()" matTooltipPosition="below" matTooltip="Nuovo articolo">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <ngx-dynamic-table
        [columnDefinitions]="columnDefinitions"
        [showFirstLastButtons]="false"
        [pageSizeOptions]="[]"
        (pageChange)="search(true)"
        (pageSizeChange)="search()"
        (sortChange)="search()"
        (onInit)="search()"
      ></ngx-dynamic-table>
    </div>
  </div>
</div>

<ng-template #detailsTemplate let-element>
  <button class="primary-99" mat-mini-fab color="primary-99" (click)="edit(element)" class="mat-elevation-z0"><mat-icon>edit</mat-icon></button>
</ng-template>

<ng-template #detailsModal>
  @if(editItem && editItem.id) {
  <h2 mat-dialog-title>Modifica articolo</h2>
  } @if(editItem && !editItem.id){
  <h2 mat-dialog-title>Nuovo articolo</h2>
  } @if (editItem && editItem.cognome_esterno != null && editItem.cognome_esterno != undefined && editItem.cognome_esterno.trim().length > 0) {
  <div style="margin-left: 25px">
    <div class="full">
      <h3>Email proveniente dall'esterno</h3>
    </div>
    <div class="full">
      <h4>Revisionare e scegliere data di pubblicazione</h4>
    </div>
    <div class="full flex">
      <p>
        Nome: <b>{{ editItem.nome_esterno }}</b>
      </p>
      <p>
        Cognome: <b>{{ editItem.cognome_esterno }}</b>
      </p>
      <p>
        Email: <b>{{ editItem.email_esterno }}</b>
      </p>
    </div>
  </div>
  }
  <mat-dialog-content class="mat-typography">
    <form class="details-form" [formGroup]="editItemForm">
      <mat-form-field class="half">
        <mat-label>titolo</mat-label>
        <input [maxlength]="499" (input)="onInputTitle($event)" matInput formControlName="titolo" />
      </mat-form-field>
      <mat-form-field class="half">
        <mat-label>Permalink</mat-label>
        <input matInput formControlName="permalink" />
      </mat-form-field>

      @if (editItem?.id) {

      <div class="full">
        <div
          class="file-upload-wrapper"
          [class.success]="uploadSuccess"
          [class.error]="uploadError"
          (drop)="onFileDrop($event)"
          (dragover)="onDragOver($event)"
        >
          <input type="file" accept="image/*" (change)="onFileChange($event)" hidden #fileInput />
          <div class="file-dropper" (click)="fileInput.click()">
            <mat-icon>upload</mat-icon>
            <p>Clicca o trascina qui per caricare un'immagine</p>
            @if (imageName() && selectedFile) {
            <div>
              <img [src]="imagePreview()" alt="Image Preview" class="image-preview" />
              <p>
                <span class="image-name">{{ imageName() }}</span> ({{ fileSize() }} KB)
              </p>
              <mat-icon class="delete-icon" (click)="removeImage()">delete</mat-icon>
            </div>
            }
          </div>
        </div>
      </div>

      <div class="full">
        @if (authService.user?.can('owners')) {
        <button mat-raised-button color="primary" (click)="generaTesto()">
          <mat-icon>auto_fix_high</mat-icon
          >{{
            editItemForm.controls["testo"].value == null || editItemForm.controls["testo"].value.trim().length === 0
              ? "Genera Testo"
              : "Migliora testo"
          }}
        </button>
        }
        <button
          [disabled]="editItemForm.controls['testo'].value == null || editItemForm.controls['testo'].value.trim().length === 0"
          style="margin-left: 10px"
          mat-raised-button
          color="p-secondary-70"
          class="mat-elevation-z0"
          (click)="miglioraFormattazione()"
        >
          <mat-icon>auto_fix_normal</mat-icon> Migliora formattazione
        </button>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-8">
            <quill-editor #quillEditor [modules]="editorModules" style="width: 100%" formControlName="testo"> </quill-editor>
          </div>
          @if (editItem?.id && authService.user?.can('owners')) {
          <div class="col-4">
            <div style="display: flex; flex-direction: column" class="form-group">
              <h4><label for="chatConEditor">Chat con editor</label></h4>
              <textarea
                [disabled]="
                  !editItemForm.controls['testo'].value ||
                  editItemForm.controls['testo'].value == null ||
                  editItemForm.controls['testo'].value.trim() === ''
                "
                class="form-control"
                style="border: 2px dotted hsl(209, 87%, 57%); border-radius: 5px; padding: 10px; margin: 10px 0"
                id="chatConEditor"
                rows="10"
                formControlName="chat_con_editor"
              ></textarea>
              <button
                [disabled]="
                  !editItemForm.controls['testo'].value ||
                  editItemForm.controls['testo'].value == null ||
                  editItemForm.controls['testo'].value.trim() === '' ||
                  !editItemForm.controls['chat_con_editor'].value ||
                  editItemForm.controls['chat_con_editor'].value == null ||
                  editItemForm.controls['chat_con_editor'].value.trim() === ''
                "
                mat-raised-button
                color="primary-99"
                (click)="submitEditorChat()"
              >
                <mat-icon>auto_fix_high</mat-icon> Genera
              </button>
            </div>
          </div>
          }
        </div>
      </div>

      @if (authService.user?.can('livello-2')) {
      <mat-form-field class="half">
        <mat-label>Pubblica</mat-label>
        <mat-select formControlName="approvato">
          <mat-option [value]="'0'">No</mat-option>
          @if (editItem?.id) { <mat-option [value]="'1'">Si</mat-option> }
        </mat-select>
      </mat-form-field>
      }
      <!-- date picker with time-->
      <mat-form-field class="half">
        <mat-label>Data Pubblicazione</mat-label>
        <input matInput type="datetime-local" formControlName="data" />
      </mat-form-field>
      <!-- categorie -->
      <mat-form-field class="half">
        <mat-label>Categoria</mat-label>
        <input type="text" placeholder="Categoria" aria-label="Number" matInput formControlName="categoria" [matAutocomplete]="categoriaDetailAuto" />
        <mat-autocomplete #categoriaDetailAuto="matAutocomplete">
          @for (categoria of categorieDetailDataSource | async; track categoria) {
          <mat-option [value]="categoria">{{ categoria.nome }}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <!-- autore -->
      <mat-form-field class="half">
        <mat-label>Autore</mat-label>
        <input type="text" placeholder="Autore" aria-label="Number" matInput formControlName="autore" [matAutocomplete]="autoreDetailAuto" />
        <mat-autocomplete #autoreDetailAuto="matAutocomplete">
          @for (autore of autoriDetailDataSource | async; track autore) {
          <mat-option [value]="autore">{{ autore.blogger }}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <!-- regione select -->
      <mat-form-field class="half">
        <mat-label>Regione</mat-label>
        <mat-select (selectionChange)="onChangeRegione($event)" formControlName="regione" [compareWith]="utilsService.compareById">
          @for(r of regioniDataSource; track r.id) {<mat-option [value]="r">{{ r.nomeregione }}</mat-option
          >}
        </mat-select>
      </mat-form-field>
      <!-- province -->
      <mat-form-field class="half">
        <mat-label>Provincia</mat-label>
        <mat-select formControlName="provincia" [compareWith]="utilsService.compareById">
          @for(p of provinceFilteredDataSource; track p.id) {<mat-option [value]="p">{{ p.nomeprovincia }}</mat-option
          >}
        </mat-select>
      </mat-form-field>
      <mat-form-field class="half">
        <mat-label>Primo Piano Nazionale</mat-label>
        <mat-select formControlName="evidenza">
          <mat-option [value]="'0'">No</mat-option>
          <mat-option [value]="'1'">Si</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field class="half">
        <mat-label>Primo Piano Regionale</mat-label>
        <mat-select formControlName="evidenza_regione">
          <mat-option [value]="0">No</mat-option>
          <mat-option [value]="1">Si</mat-option>
        </mat-select>
      </mat-form-field> -->
      <div class="half">
        <button mat-raised-button color="primary" (click)="generaKeywords()"><mat-icon>auto_fix_high</mat-icon>Genera Keywords</button>
        <mat-form-field style="margin-left: 10px; width: 70%">
          <mat-label>Keywords</mat-label>
          <input matInput formControlName="keywords" />
        </mat-form-field>
      </div>

      }
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button (click)="save()">Salva</button>
    @if (editItem?.id) {
    <button mat-stroked-button color="p-secondary-70" (click)="visualizzaSulSito(true)">Aggiorna Cache</button>
    <button [disabled]="editItem?.shared == 1" mat-raised-button (click)="publishSocial()">
      {{ editItem?.shared == 1 ? "Già pubblicato sui social" : "Pubblica sui social" }}
    </button>
    <button mat-stroked-button color="p-secondary-70" (click)="visualizzaSulSito()">Visualizza sul sito</button>
    } @if (editItem?.id && authService.user?.can('owners')) { <button mat-button (click)="delete()">Elimina</button> }
    <button mat-raised-button mat-dialog-close>Chiudi</button>
  </mat-dialog-actions>
</ng-template>
