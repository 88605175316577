import Quill from 'quill';
const BlockEmbed: any = Quill.import("blots/block/embed");
const Link: any = Quill.import("formats/link");

export class EmbedPDF extends BlockEmbed {
    static blotName = "embed-pdf";
    static tagName = "p";
    static className = "embed-pdf";

    static create(value: any) {
        const node = super.create();

        // Check if value is an object containing the necessary properties
        if (typeof value === 'object' && value.href) {
            const child = document.createElement("a");
            child.setAttribute('href', value.href);
            child.setAttribute('target', '_blank');
            child.setAttribute('download', value.name);
            child.innerText = value.name;
            child.classList.add("embed-pdf-item");
            node.appendChild(child);
        } else {
            node.innerHTML = '<p class="embed-pdf"></p>'; // fallback in caso di valore non valido
        }

        return node;
    }

    static sanitize(url: any) {
        return Link.sanitize(url);
    }

    static value(domNode: any) {
        const a = domNode.querySelector('a');
        return {
            href: a.getAttribute('href'),
            name: a.innerText
        };
    }
}

Quill.register(EmbedPDF);