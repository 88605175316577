import { Regione } from "./regione.model";

export class Provincia {
  id: number;
  nomeprovincia!: string;
  regione!: Regione;
  siglaprovincia!: string;

  constructor(record?: any) {
    this.id = record?.id;
    record = record || {};
    Object.assign(this, record);
  }

   toString(): string {
    return this.nomeprovincia;
  }
}
