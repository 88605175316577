import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { BackToTopComponent } from '@shared/back-to-top/back-to-top.component';
import { BreadcrumbComponent } from '@shared/breadcrumb/breadcrumb.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { Observable } from 'rxjs';
import { UtilsService } from '@services/utils.service';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [RouterOutlet, TranslateModule, LoaderComponent, BackToTopComponent, BreadcrumbComponent, SidebarComponent, NgClass, AsyncPipe, MatIconModule],
  templateUrl: './main.component.html',
})
export class MainComponent {
  authService = inject(AuthService);
  utilsService = inject(UtilsService);
  readonly isMobile$: Observable<boolean>;

  constructor() {
    this.initializeApp();
    this.authService.checkAuth()?.subscribe();
    this.isMobile$ = this.utilsService.isMobile$;
  }

  initializeApp() {
    if (window.location.pathname.includes('/be/auth')) {
      window.open(window.location.href, '_self');
    }
    if (window.location.search.includes('?')) {
      const url = new URL(window.location.href);
      if (url.searchParams.get('token') !== null) {
        this.authService.token = url.searchParams.get('token') || '';
        window.location.href = '/';
      }
    }
  }
}
