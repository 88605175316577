import { AsyncPipe, NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { mainConfig } from '@config/main.config';
import { ThemeService } from '@services/themes.service';
import { UtilsService } from '@services/utils.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-toggle-theme',
  standalone: true,
  imports: [...mainConfig.material, AsyncPipe, NgClass],
  templateUrl: './toggle-theme.component.html',
})
export class ToggleThemeComponent {
  toggleService = inject(ThemeService);
  utilsService = inject(UtilsService);
  readonly isMobile$: Observable<boolean>;

  constructor() {
    this.isMobile$ = this.utilsService.isMobile$;
  }
  switchTheme(): void {
    this.toggleService.toggleTheme();
  }

  isDarkThemeActive(): boolean {
    return this.toggleService.isDarkThemeActive();
  }

  getThemeToggleLabel(): string {
    return this.toggleService.getToggleLabel();
  }
}
