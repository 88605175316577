import { Component, computed } from '@angular/core';
import { NotificationsService } from '@services/notifications.service';
import { NgClass } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  imports: [NgClass],
})
export class SnackBarComponent {
  header = computed(
    () => this.notificationsService.snackBarMessage().content.header
  );
  content = computed(
    () => this.notificationsService.snackBarMessage().content.body
  );
  type = computed(
    () => this.notificationsService.snackBarMessage().content.type
  );
  constructor(public notificationsService: NotificationsService) {}
}
