import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";

const verticalPosition = {
  top: 'top',
  bottom: 'bottom',
} as const;

const horizontalPosition = {
  start: 'start',
  center: 'center',
  end: 'end',
} as const;

export interface SnackbarConfig {
  duration: number;
  verticalPosition: MatSnackBarVerticalPosition;
  horizontalPosition: MatSnackBarHorizontalPosition;
  panelClass: string[];
}

export const snackbarConfig: SnackbarConfig = {
  duration: 8000,
  verticalPosition: verticalPosition.bottom,
  horizontalPosition: horizontalPosition.end,
  panelClass: [],
};
