import { Component, Inject, OnInit, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { mainConfig } from '@config/main.config';
import { AuthService } from '@services/auth.service';
import { UsersService } from '@services/users.service';
import { UtilsService } from '@services/utils.service';
import { COMMON_APP_CONFIG } from 'src/app/core/tokens/common-config.token';

@Component({
  standalone: true,
  imports: [...mainConfig.material, RouterLink],
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  authService = inject(AuthService);
  utilsService = inject(UtilsService);
  usersService = inject(UsersService);

  constructor(@Inject(COMMON_APP_CONFIG) private mainConfig: Required<{ api: { authUrl: string } }>) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.authService._user) {
        this.authService._user.last_login = new Date();
        this.usersService.save(this.authService._user).subscribe();
      }
    }, 1000);
  }

  loginUrl() {
    return this.mainConfig.api.authUrl;
  }
}
