<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-center py-5">
      <form [formGroup]="profileForm" class="text-center">
        <div>
          <h2>
            Gestione Profilo
          </h2>
        </div>
        <div class="details-form">
          <mat-form-field class="half">
            <mat-label>Nome e Cognome</mat-label>
            <input matInput formControlName="blogger" placeholder="Nome e Cognome">
          </mat-form-field>
          <mat-form-field class="half">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" placeholder="Email">
          </mat-form-field>
          <mat-form-field class="half">
            <mat-label>Url Profilo Google</mat-label>
            <input matInput formControlName="url_google" placeholder="Url Profilo Google">
          </mat-form-field>
          <mat-form-field class="half">
            <mat-label>Url Profilo Facebook</mat-label>
            <input matInput formControlName="url_facebook" placeholder="Url Profilo Facebook">
          </mat-form-field>
          <mat-form-field class="half">
            <mat-label>Url Profilo Twitter (X)</mat-label>
            <input matInput formControlName="url_twitter" placeholder="Url Profilo Twitter (X)">
          </mat-form-field>
          <mat-form-field class="half">
            <mat-label>Url Profilo Linkedin</mat-label>
            <input matInput formControlName="url_linkedin" placeholder="Url Profilo Linkedin">
          </mat-form-field>
          <!-- Upload avatar -->
          <div class="half">
            <div
              class="file-upload-wrapper"
              [class.success]="uploadSuccess"
              [class.error]="uploadError"
              (drop)="onFileDrop($event)"
              (dragover)="onDragOver($event)"
            >
              <input type="file" accept="image/*" (change)="onFileChange($event)" hidden #fileInput />
              <div class="file-dropper" (click)="fileInput.click()">
                <mat-icon>upload</mat-icon>
                <p>Clicca o trascina qui per caricare un'immagine</p>
                @if (imageName() && selectedFile) {
                <div>
                  <img [src]="imagePreview()" alt="Image Preview" class="image-preview" />
                  <mat-icon class="delete-icon" (click)="removeImage()">delete</mat-icon>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div class="text-end">
          <button class="" (click)="save()" mat-raised-button color="primary">SALVA</button>
        </div>
      </form>
    </div>
</div>