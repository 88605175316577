import { Inject, Injectable } from '@angular/core';
import { Articolo } from '@models/articolo.model';
import { NgxCrudService } from '@sinapsys/ngx-crud';
import { Observable, map } from 'rxjs';
import { COMMON_APP_CONFIG } from '../tokens/common-config.token';

@Injectable({
  providedIn: 'root',
})
export class AIService extends NgxCrudService<Articolo, number> {
  constructor(@Inject(COMMON_APP_CONFIG) private mainConfig: Required<{ api: { apiBaseUrl: string } }>) {
    super({ apiBaseUrl: '' });
    this._baseUrl += this.mainConfig.api.apiBaseUrl + 'ai/';
  }

  callAI(text: string): Observable<string> {
    return this.http.post<string>(this._baseUrl + 'call-gpt', { text }, { withCredentials: true });
  }

  socialPublish(url: string, id: string): Observable<string> {
    return this.http.post<string>(this._baseUrl + 'social-publish', { url, id }, { withCredentials: true });
  }
}
