import { AfterViewInit, Component, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { mainConfig } from '@config/main.config';
import { Subject } from 'rxjs';

@Component({
  standalone: true,
  imports: [mainConfig.material, mainConfig.formModules],
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent implements OnInit, AfterViewInit {
  @Input()
  title!: string;

  @Input()
  text!: string;

  @Input()
  defaultValue: string = '';

  @Input()
  saveLabel: string = 'Salva';

  @Input()
  cancelLabel: string = 'Annulla';
  
  @Output()
  afterSave: Subject<any> = new Subject<any>();

  returnValue: any = '';

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PromptDialogComponent>
  ) {

  }
  ngAfterViewInit(): void {
    const dialogSurface = document.querySelector('.mat-mdc-dialog-surface .mdc-dialog__surface');
    if (dialogSurface) {
      dialogSurface.setAttribute('style', 'overflow: hidden !important');
    }
  }

  ngOnInit(): void {
    if (this.defaultValue != '') {
      this.returnValue = this.defaultValue;
    }
    this.dialogRef.afterClosed().subscribe(result => {
      this.afterSave.next(null);
      return true;
    });
  }


  save() {
    this.afterSave.next(this.returnValue);
    this.dialogRef.close();
  }

  cancel() {
    this.afterSave.next(null);
    this.dialogRef.close();
  }
}
