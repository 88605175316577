
export class Regione {
  id: number;
  nomeregione!: string;
  permalinkregione!: string;

  constructor(record?: any) {
    this.id = record?.id;
    record = record || {};
    Object.assign(this, record);
  }

   toString(): string {
    return this.nomeregione;
  }
}
