import * as i0 from '@angular/core';
import { Component, ViewChild, Input, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/material/paginator';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import * as i3 from '@angular/material/sort';
import { MatSort, MatSortModule } from '@angular/material/sort';
import * as i4 from '@angular/material/table';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Subject } from 'rxjs';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = (a0, a1) => ({
  "max-width": a0,
  "min-width": a1
});
const _c1 = a0 => ({
  $implicit: a0
});
function NgxDynamicTableComponent_ng_container_2_mat_header_cell_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-header-cell", 11);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const column_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", !column_r1.sortable)("ngStyle", i0.ɵɵpureFunction2(3, _c0, column_r1.width || "auto", column_r1.width || "auto"));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", column_r1.header || ctx_r1.capitalize(column_r1.id), " ");
  }
}
function NgxDynamicTableComponent_ng_container_2_mat_cell_2_ng_container_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function NgxDynamicTableComponent_ng_container_2_mat_cell_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, NgxDynamicTableComponent_ng_container_2_mat_cell_2_ng_container_1_ng_container_1_Template, 1, 0, "ng-container", 14);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const element_r3 = i0.ɵɵnextContext().$implicit;
    const column_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", column_r1.template)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c1, element_r3));
  }
}
function NgxDynamicTableComponent_ng_container_2_mat_cell_2_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const element_r3 = i0.ɵɵnextContext().$implicit;
    const column_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵtextInterpolate1(" ", (column_r1.parser ? column_r1.parser(element_r3[column_r1.id]) : element_r3[column_r1.id]) || "-", " ");
  }
}
function NgxDynamicTableComponent_ng_container_2_mat_cell_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-cell", 12);
    i0.ɵɵtemplate(1, NgxDynamicTableComponent_ng_container_2_mat_cell_2_ng_container_1_Template, 2, 4, "ng-container", 13)(2, NgxDynamicTableComponent_ng_container_2_mat_cell_2_ng_template_2_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const default_r4 = i0.ɵɵreference(3);
    const column_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction2(4, _c0, column_r1.width || "auto", column_r1.width || "auto"));
    i0.ɵɵattribute("data-header", column_r1.header || ctx_r1.capitalize(column_r1.id));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", column_r1.template)("ngIfElse", default_r4);
  }
}
function NgxDynamicTableComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 8);
    i0.ɵɵtemplate(1, NgxDynamicTableComponent_ng_container_2_mat_header_cell_1_Template, 2, 6, "mat-header-cell", 9)(2, NgxDynamicTableComponent_ng_container_2_mat_cell_2_Template, 4, 7, "mat-cell", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const column_r1 = ctx.$implicit;
    i0.ɵɵproperty("matColumnDef", column_r1.id);
  }
}
function NgxDynamicTableComponent_mat_header_row_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "mat-header-row");
  }
}
function NgxDynamicTableComponent_mat_row_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "mat-row");
  }
}
function NgxDynamicTableComponent_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.noElementsMessage, " ");
  }
}
class NgxDynamicTableComponent {
  paginator;
  sort;
  dataSource = new MatTableDataSource();
  get displayedColumns() {
    return this.columnDefinitions.map(c => c.id);
  }
  columnDefinitions = [];
  pageSizeOptions = [25, 50, 100];
  showFirstLastButtons = true;
  noElementsMessage = "Nessun elemento presente";
  pageChange = new Subject();
  pageSizeChange = new Subject();
  sortChange = new Subject();
  onInit = new Subject();
  constructor() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.initSort();
      this.initPaginator();
      this.onInit.next();
    });
  }
  initSort() {
    this.sort.active = "id";
    this.sort.direction = "desc";
    this.sort.sortChange.subscribe(() => {
      if (!this.dataSource.data.length) return;
      this.paginator.pageIndex = 0;
      this.sortChange.next({
        active: this.sort.active,
        direction: this.sort.direction
      });
    });
  }
  initPaginator() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 25;
    this.paginator.page.subscribe(() => {
      this.pageChange.next(this.paginator.pageIndex);
    });
  }
  populate(result) {
    this.dataSource.data = result.items;
    let interval = setInterval(() => {
      if (this.paginator) {
        this.paginator.length = result.total;
        clearInterval(interval);
      }
    }, 200);
  }
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  static ɵfac = function NgxDynamicTableComponent_Factory(t) {
    return new (t || NgxDynamicTableComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: NgxDynamicTableComponent,
    selectors: [["ngx-dynamic-table"]],
    viewQuery: function NgxDynamicTableComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(MatPaginator, 5);
        i0.ɵɵviewQuery(MatSort, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.paginator = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sort = _t.first);
      }
    },
    inputs: {
      columnDefinitions: "columnDefinitions",
      pageSizeOptions: "pageSizeOptions",
      showFirstLastButtons: "showFirstLastButtons",
      noElementsMessage: "noElementsMessage"
    },
    outputs: {
      pageChange: "pageChange",
      pageSizeChange: "pageSizeChange",
      sortChange: "sortChange",
      onInit: "onInit"
    },
    decls: 7,
    vars: 7,
    consts: [["default", ""], [1, "mat-elevation-z8", "mb-4"], ["matSort", "", 3, "dataSource"], [3, "matColumnDef", 4, "ngFor", "ngForOf"], [4, "matHeaderRowDef"], [4, "matRowDef", "matRowDefColumns"], ["class", "p-4 text-center", 4, "ngIf"], [3, "pageSizeOptions", "showFirstLastButtons"], [3, "matColumnDef"], ["mat-sort-header", "", 3, "disabled", "ngStyle", 4, "matHeaderCellDef"], [3, "ngStyle", 4, "matCellDef"], ["mat-sort-header", "", 3, "disabled", "ngStyle"], [3, "ngStyle"], [4, "ngIf", "ngIfElse"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "p-4", "text-center"]],
    template: function NgxDynamicTableComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 1)(1, "mat-table", 2);
        i0.ɵɵtemplate(2, NgxDynamicTableComponent_ng_container_2_Template, 3, 1, "ng-container", 3)(3, NgxDynamicTableComponent_mat_header_row_3_Template, 1, 0, "mat-header-row", 4)(4, NgxDynamicTableComponent_mat_row_4_Template, 1, 0, "mat-row", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(5, NgxDynamicTableComponent_div_5_Template, 2, 1, "div", 6);
        i0.ɵɵelement(6, "mat-paginator", 7);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵproperty("dataSource", ctx.dataSource);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngForOf", ctx.columnDefinitions);
        i0.ɵɵadvance();
        i0.ɵɵproperty("matHeaderRowDef", ctx.displayedColumns);
        i0.ɵɵadvance();
        i0.ɵɵproperty("matRowDefColumns", ctx.displayedColumns);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.dataSource.data.length === 0);
        i0.ɵɵadvance();
        i0.ɵɵproperty("pageSizeOptions", ctx.pageSizeOptions)("showFirstLastButtons", ctx.showFirstLastButtons);
      }
    },
    dependencies: [i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i2.MatPaginator, i3.MatSort, i3.MatSortHeader, i4.MatTable, i4.MatHeaderCellDef, i4.MatHeaderRowDef, i4.MatColumnDef, i4.MatCellDef, i4.MatRowDef, i4.MatHeaderCell, i4.MatCell, i4.MatHeaderRow, i4.MatRow],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxDynamicTableComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-dynamic-table',
      template: `
    <div class="mat-elevation-z8 mb-4">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container *ngFor="let column of columnDefinitions" [matColumnDef]="$any(column.id)">
          <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable" [ngStyle]="{
            'max-width': column.width || 'auto',
            'min-width': column.width || 'auto'
            }">
            {{ column.header || capitalize(column.id) }} 
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [ngStyle]="{
            'max-width': column.width || 'auto',
            'min-width': column.width || 'auto'
            }" [attr.data-header]="column.header || capitalize(column.id)">
            <ng-container *ngIf="column.template; else default">
              <ng-container *ngTemplateOutlet="column.template; context: { $implicit: element }"></ng-container>
            </ng-container>
            <ng-template #default>
              {{ (column.parser ? column.parser(element[column.id]) : element[column.id]) || '-' }}
            </ng-template>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <div class="p-4 text-center" *ngIf="dataSource.data.length === 0">
        {{ noElementsMessage }}
      </div>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="showFirstLastButtons"></mat-paginator>
    </div>
  `
    }]
  }], () => [], {
    paginator: [{
      type: ViewChild,
      args: [MatPaginator, {
        static: false
      }]
    }],
    sort: [{
      type: ViewChild,
      args: [MatSort, {
        static: false
      }]
    }],
    columnDefinitions: [{
      type: Input
    }],
    pageSizeOptions: [{
      type: Input
    }],
    showFirstLastButtons: [{
      type: Input
    }],
    noElementsMessage: [{
      type: Input
    }],
    pageChange: [{
      type: Output
    }],
    pageSizeChange: [{
      type: Output
    }],
    sortChange: [{
      type: Output
    }],
    onInit: [{
      type: Output
    }]
  });
})();
class NgxDynamicTableModule {
  static forRoot() {
    return {
      ngModule: NgxDynamicTableModule,
      providers: []
    };
  }
  static ɵfac = function NgxDynamicTableModule_Factory(t) {
    return new (t || NgxDynamicTableModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxDynamicTableModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, MatPaginatorModule, MatSortModule, MatTableModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxDynamicTableModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxDynamicTableComponent],
      imports: [CommonModule, MatPaginatorModule, MatSortModule, MatTableModule],
      exports: [NgxDynamicTableComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-dynamic-table
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxDynamicTableComponent, NgxDynamicTableModule };
