import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { mainConfig } from '@config/main.config';
import { fadeInOut } from '@public/styles/animations/fade-in-out.animation';
import { UtilsService } from '@services/utils.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-back-to-top',
  standalone: true,
  imports: [AsyncPipe, ...mainConfig.material],
  templateUrl: './back-to-top.component.html',
  animations: [fadeInOut],
})
export class BackToTopComponent {
  visible: Observable<boolean>;
  public utilsService = inject(UtilsService);

  constructor() {
    this.visible = this.utilsService.isScrolled$;
  }
}
