import * as i1 from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from '@angular/core';
import { Injectable, Inject, EventEmitter, Component, ViewChild, Input, Output, NgModule } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import * as i4 from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import * as i5 from '@angular/material/toolbar';
import { MatToolbarModule } from '@angular/material/toolbar';
import * as i6 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
const _c0 = ["fileUpload"];
function NgxUploadComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5)(1, "mat-toolbar", 6)(2, "mat-toolbar-row")(3, "span", 7);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "button", 8)(6, "mat-icon");
    i0.ɵɵtext(7, "close");
    i0.ɵɵelementEnd()()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(ctx_r0.title);
  }
}
function NgxUploadComponent_button_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function NgxUploadComponent_button_4_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.upload());
    });
    i0.ɵɵelementStart(1, "mat-icon");
    i0.ɵɵtext(2, "cloud_upload");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(3, " Upload ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r0.disabled);
  }
}
function NgxUploadComponent_mat_dialog_actions_5_button_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 12)(1, "mat-icon");
    i0.ɵɵtext(2, "close");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(3, " Cancel ");
    i0.ɵɵelementEnd();
  }
}
function NgxUploadComponent_mat_dialog_actions_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-dialog-actions", 10)(1, "button", 9);
    i0.ɵɵlistener("click", function NgxUploadComponent_mat_dialog_actions_5_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.upload());
    });
    i0.ɵɵelementStart(2, "mat-icon");
    i0.ɵɵtext(3, "cloud_upload");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(4, " Upload ");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, NgxUploadComponent_mat_dialog_actions_5_button_5_Template, 4, 0, "button", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r0.disabled);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngIf", ctx_r0.showUploadButton);
  }
}
class NgxUploadService {
  http;
  config;
  _baseUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      withCredentials: 'true',
      responseType: 'json',
      reportProgress: 'true',
      observe: 'events'
    })
  };
  constructor(http, config) {
    this.http = http;
    this.config = config;
    this._baseUrl = this.config.mediaBaseUrl || '';
  }
  upload(formData) {
    return this.http.post(this._baseUrl + 'upload', formData, this.httpOptions);
  }
  download(blob) {
    return this.http.get(this._baseUrl + 'files/' + blob.id, {
      responseType: "blob",
      withCredentials: true
    }).pipe(catchError(NgxUploadService.parseErrorBlob));
  }
  save(blob) {
    return this.http.put(this._baseUrl + 'files/' + blob.id, blob, {
      withCredentials: true
    });
  }
  delete(blob) {
    return this.http.delete(this._baseUrl + 'files/' + blob.id, {
      withCredentials: true
    });
  }
  static parseErrorBlob(err) {
    const reader = new FileReader();
    const obs = new Observable(observer => {
      reader.onloadend = e => {
        observer.error({
          error: reader.result
        });
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }
  static ɵfac = function NgxUploadService_Factory(t) {
    return new (t || NgxUploadService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject('ngxUploadConfiguration'));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NgxUploadService,
    factory: NgxUploadService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxUploadService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['ngxUploadConfiguration']
    }]
  }], null);
})();
class NgxUploadComponent {
  uploadService;
  fileUpload;
  asDialog = true;
  title = "Upload File";
  metadata = {};
  disabled = false;
  cancel = new EventEmitter();
  afterUpload = new EventEmitter();
  afterError = new EventEmitter();
  allowedTypes = [];
  fieldName = 'file';
  multiple = false;
  showUploadButton = true;
  autoSubmit = false;
  constructor(uploadService) {
    this.uploadService = uploadService;
  }
  ngOnInit() {}
  ngAfterViewInit() {
    const fileUpload = this.fileUpload.nativeElement;
    if (this.autoSubmit) {
      fileUpload.onchange = () => {
        this.upload();
      };
    }
  }
  upload() {
    const fileUpload = this.fileUpload.nativeElement;
    if (!fileUpload.files || !fileUpload.files[0]) {
      this.afterError.emit('Select a file first');
      return;
    }
    for (let f of fileUpload.files) this.uploadFile(f);
  }
  uploadFile(file) {
    if (this.allowedTypes.length && this.allowedTypes.indexOf('.' + file.name.split('.').pop()) < 0) {
      this.afterError.emit('Allowed file formats: ' + this.allowedTypes.join(', '));
      return;
    }
    const formData = new FormData();
    formData.append(this.fieldName, file);
    for (let f in this.metadata) formData.append(f, this.metadata[f]);
    file.inProgress = true;
    this.uploadService.upload(formData).subscribe(res => {
      this.fileUpload.nativeElement.value = '';
      this.afterUpload.emit(res.data);
    }, err => {
      this.afterError.emit(err);
    });
  }
  getAllowedTypes() {
    return this.allowedTypes ? this.allowedTypes.join(',') : '';
  }
  static ɵfac = function NgxUploadComponent_Factory(t) {
    return new (t || NgxUploadComponent)(i0.ɵɵdirectiveInject(NgxUploadService));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: NgxUploadComponent,
    selectors: [["ngx-upload"]],
    viewQuery: function NgxUploadComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileUpload = _t.first);
      }
    },
    inputs: {
      asDialog: "asDialog",
      title: "title",
      metadata: "metadata",
      disabled: "disabled",
      allowedTypes: "allowedTypes",
      fieldName: "fieldName",
      multiple: "multiple",
      showUploadButton: "showUploadButton",
      autoSubmit: "autoSubmit"
    },
    outputs: {
      cancel: "cancel",
      afterUpload: "afterUpload",
      afterError: "afterError"
    },
    decls: 6,
    vars: 6,
    consts: [["fileUpload", ""], ["class", "custom-dialog-header", 4, "ngIf"], ["type", "file", "id", "fileUpload", "name", "fileUpload", 3, "disabled", "multiple", "accept"], ["mat-raised-button", "", "class", "mat-blue-900-bg", "matTooltip", "Upload", "aria-label", "Upload", 3, "disabled", "click", 4, "ngIf"], ["align", "center", 4, "ngIf"], [1, "custom-dialog-header"], ["matDialogTitle", "", 1, "mat-blue-900-bg", "m-0"], [1, "title", "dialog-title"], ["mat-button", "", "mat-dialog-close", "", "matTooltip", "Close", "aria-label", "Close", 1, "mat-icon-button"], ["mat-raised-button", "", "matTooltip", "Upload", "aria-label", "Upload", 1, "mat-blue-900-bg", 3, "click", "disabled"], ["align", "center"], ["mat-raised-button", "", "mat-dialog-close", "", "matTooltip", "Cancel", "aria-label", "Cancel", 4, "ngIf"], ["mat-raised-button", "", "mat-dialog-close", "", "matTooltip", "Cancel", "aria-label", "Cancel"]],
    template: function NgxUploadComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, NgxUploadComponent_div_0_Template, 8, 1, "div", 1);
        i0.ɵɵelementStart(1, "mat-dialog-content");
        i0.ɵɵelement(2, "input", 2, 0);
        i0.ɵɵtemplate(4, NgxUploadComponent_button_4_Template, 4, 1, "button", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(5, NgxUploadComponent_mat_dialog_actions_5_Template, 6, 2, "mat-dialog-actions", 4);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.asDialog);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("disabled", ctx.disabled)("multiple", ctx.multiple)("accept", ctx.getAllowedTypes());
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", !ctx.asDialog && ctx.showUploadButton);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.asDialog);
      }
    },
    dependencies: [i2.NgIf, i3.MatIcon, i4.MatDialogClose, i4.MatDialogTitle, i4.MatDialogActions, i4.MatDialogContent, i5.MatToolbar, i5.MatToolbarRow, i6.MatButton],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxUploadComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-upload',
      template: `
    <div class="custom-dialog-header" *ngIf="asDialog">
      <mat-toolbar matDialogTitle class="mat-blue-900-bg m-0">
        <mat-toolbar-row>
          <span class="title dialog-title">{{title}}</span>
          <button mat-button class="mat-icon-button" mat-dialog-close matTooltip="Close" aria-label="Close">
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>

    <mat-dialog-content>
      <input #fileUpload type="file" id="fileUpload" name="fileUpload" [disabled]="disabled" [multiple]="multiple" [accept]="getAllowedTypes()">
      <button *ngIf="!asDialog && showUploadButton" mat-raised-button (click)="upload()" class="mat-blue-900-bg" matTooltip="Upload"
        aria-label="Upload" [disabled]="disabled">
        <mat-icon>cloud_upload</mat-icon> Upload
      </button>
    </mat-dialog-content>

    <mat-dialog-actions align="center" *ngIf="asDialog">
      <button mat-raised-button (click)="upload()" class="mat-blue-900-bg" matTooltip="Upload" aria-label="Upload"
        [disabled]="disabled">
        <mat-icon>cloud_upload</mat-icon> Upload
      </button>
      <button mat-raised-button mat-dialog-close matTooltip="Cancel" aria-label="Cancel" *ngIf="showUploadButton">
        <mat-icon>close</mat-icon> Cancel
      </button>
    </mat-dialog-actions>
  `
    }]
  }], () => [{
    type: NgxUploadService
  }], {
    fileUpload: [{
      type: ViewChild,
      args: ['fileUpload', {
        static: false
      }]
    }],
    asDialog: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    metadata: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    cancel: [{
      type: Output
    }],
    afterUpload: [{
      type: Output
    }],
    afterError: [{
      type: Output
    }],
    allowedTypes: [{
      type: Input
    }],
    fieldName: [{
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    showUploadButton: [{
      type: Input
    }],
    autoSubmit: [{
      type: Input
    }]
  });
})();
class NgxUploadFile {
  id;
  createdAt;
  updatedAt;
  deletedAt;
  originalFilename;
  blobFilename;
  url;
  contentType;
  size;
  field;
  metadata;
  constructor(record) {
    record = record || {};
    Object.assign(this, record);
  }
  toString() {
    return this.originalFilename;
  }
  toJson() {
    return {
      id: this.id
    };
  }
}
class NgxUploadModule {
  static forRoot(ngxUploadConfiguration) {
    return {
      ngModule: NgxUploadModule,
      providers: [NgxUploadService, {
        provide: 'ngxUploadConfiguration',
        useValue: ngxUploadConfiguration
      }]
    };
  }
  static ɵfac = function NgxUploadModule_Factory(t) {
    return new (t || NgxUploadModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxUploadModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, MatIconModule, MatDialogModule, MatToolbarModule, MatButtonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxUploadModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxUploadComponent],
      imports: [CommonModule, MatIconModule, MatDialogModule, MatToolbarModule, MatButtonModule],
      exports: [NgxUploadComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-upload
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxUploadComponent, NgxUploadFile, NgxUploadModule, NgxUploadService };
