import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { mainConfig } from '@config/main.config';
import { Categoria } from '@models/categoria.model';
import { AuthService } from '@services/auth.service';
import { CategorieService } from '@services/categorie.service';
import { UtilsService } from '@services/utils.service';
import { PageRequest } from '@sinapsys/ngx-crud';
import { ColumnDefinition, NgxDynamicTableComponent, NgxDynamicTableModule } from '@sinapsys/ngx-dynamic-table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-categorie',
  standalone: true,
  imports: [NgxDynamicTableModule, mainConfig.material, mainConfig.formModules],
  templateUrl: './categorie.component.html',
})
export class CategorieComponent implements OnInit {
  @ViewChild(NgxDynamicTableComponent, { static: false }) table!: NgxDynamicTableComponent<Categoria>;
  @ViewChild('detailsModal', { static: true }) detailsModal!: TemplateRef<unknown>;
  @ViewChild('detailsTemplate', { static: true }) detailsTemplate!: TemplateRef<unknown>;

  columnDefinitions: ColumnDefinition<Categoria>[] = [];
  searchSubscription?: Subscription;
  searchForm: FormGroup;
  editItemForm: FormGroup;
  editItem?: Categoria;
  dialogRef?: MatDialogRef<unknown>;
  grantsDataSource: string[] = [];

  constructor(
    public utilsService: UtilsService,
    public datePipe: DatePipe,
    private categorieService: CategorieService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    this.searchForm = new FormGroup({
      nome: new FormControl<string | undefined>(undefined),
      rubrica: new FormControl<number | undefined>(undefined)
    });
    this.editItemForm = new FormGroup({
      id: new FormControl<number | undefined>(undefined),
      nome: new FormControl<string | undefined>(undefined),
      permalink: new FormControl<string | undefined>(undefined),
      rubrica: new FormControl<number | undefined>(undefined),
    });
  }

  ngOnInit(): void {
    this.columnDefinitions = [
      { id: 'id', header: 'ID', width: '76px', sortable: true },
      { id: 'nome', header: 'Nome', sortable: true },
      { id: 'rubrica', header: 'Rubrica', parser: (v) => v ? 'Si' : 'No', sortable: true },
      { id: '_', header: 'Dettagli', template: this.detailsTemplate, width: '100px' },
    ];
  }

  search(keepPage?: boolean) {
    let raw = this.searchForm.getRawValue();
    for (let key in raw) {
      if (raw[key] !== null) {
        raw[key] = { $like: raw[key] };
      }
    }
    if (!keepPage) this.table.paginator.pageIndex = 0;

    let pageRequest: PageRequest = new PageRequest(
      this.table.paginator.pageIndex,
      this.table.paginator.pageSize,
      this.table.sort.direction,
      this.table.sort.active
    );

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    this.searchSubscription = this.categorieService.search(raw, pageRequest).subscribe({
      next: (res) => {
        res.items = res.items.map((item) => new Categoria(item));
        this.table.populate(res);
      },
      error: (err) => {
        this.utilsService.defaultHttpErrorHandler(err);
      },
    });
  }

  clear() {
    this.searchForm.reset();
    this.table.sort.active = 'id';
    this.table.sort.direction = 'desc';
    this.search();
  }

  new() {
    this.edit(new Categoria());
  }

  edit(item: Categoria) {
    this.editItemForm.reset();
    this.editItemForm.patchValue(item);
    this.editItem = item;
    this.dialogRef = this.dialog.open(this.detailsModal, { width: '40%' });
  }

  save() {
    let raw = this.editItemForm.getRawValue();
    this.categorieService.save(raw).subscribe({
      next: (res) => {
        this.utilsService.notificationsService.showNotification('', 'Categoria salvata con successo', 'success');
        this.editItem = new Categoria(res);
        this.editItemForm.patchValue(this.editItem);
        this.search(true);
      },
      error: (err) => {
        this.utilsService.defaultHttpErrorHandler(err);
      },
    });
  }

  delete() {
    if (!confirm('Sei sicuro di voler eliminare questa Categoria?')) return;
    let raw = this.editItemForm.getRawValue();
    this.categorieService.delete(raw.id).subscribe({
      next: (res) => {
        this.utilsService.notificationsService.showNotification('', 'Categoria eliminata con successo', 'success');
        this.search(true);
        this.dialogRef?.close();
      },
      error: (err) => {
        this.utilsService.defaultHttpErrorHandler(err);
      },
    });
  }
}
