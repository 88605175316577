import { HttpParams, HttpClient } from '@angular/common/http';
import * as i0 from '@angular/core';
import { inject, Injectable, Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
class Model {
  id;
  createdAt;
  updatedAt;
  deletedAt;
  constructor(record) {
    record = record || {};
    Object.assign(this, record);
  }
  toJson() {
    return {
      id: this.id
    };
  }
}
class PageRequest {
  page;
  size;
  sortDirection;
  sortProperty;
  constructor(page, size, sortDirection, sortProperty) {
    this.page = page || 0;
    this.size = size || -1;
    this.sortDirection = sortDirection || 'asc';
    this.sortProperty = sortProperty || 'id';
  }
  toHttpParams() {
    let params = new HttpParams();
    params = params.append('page', this.page.toString());
    params = params.append('size', this.size.toString());
    if (this.sortDirection) params = params.append('sortDirection', this.sortDirection);
    if (this.sortProperty) params = params.append('sortProperty', this.sortProperty);
    return params;
  }
  toQueryParams() {
    let queryParams = {
      page: this.page,
      size: this.size
    };
    if (this.sortDirection) queryParams.sortDirection = this.sortDirection;
    if (this.sortProperty) queryParams.sortProperty = this.sortProperty;
    return queryParams;
  }
}
class NgxCrudService {
  config;
  _baseUrl;
  http;
  constructor(config) {
    this.config = config;
    this.http = inject(HttpClient);
    this._baseUrl = this.config.apiBaseUrl || '';
  }
  search(params, pageRequest) {
    if (!pageRequest) pageRequest = new PageRequest();
    let queryParams = pageRequest.toQueryParams();
    queryParams.q = params;
    return this.http.post(this._baseUrl + 'search', queryParams, {
      responseType: 'json',
      withCredentials: true
    });
  }
  list() {
    return this.http.get(this._baseUrl, {
      responseType: 'json',
      withCredentials: true
    });
  }
  get(id) {
    return this.http.get(this._baseUrl + id, {
      responseType: 'json',
      withCredentials: true
    });
  }
  save(object) {
    if (object.id) {
      return this.edit(object);
    }
    return this.create(object);
  }
  edit(object) {
    let id = object.id;
    let data = Object.assign({}, object);
    delete data.id;
    return this.http.put(this._baseUrl + id, data, {
      responseType: 'json',
      withCredentials: true
    });
  }
  create(object) {
    delete object.id;
    return this.http.post(this._baseUrl, object, {
      responseType: 'json',
      withCredentials: true
    });
  }
  delete(id) {
    return this.http.delete(this._baseUrl + id, {
      responseType: 'json',
      withCredentials: true
    });
  }
  static ɵfac = function NgxCrudService_Factory(t) {
    return new (t || NgxCrudService)(i0.ɵɵinject('ngxCrudConfig'));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NgxCrudService,
    factory: NgxCrudService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCrudService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['ngxCrudConfig']
    }]
  }], null);
})();
class NgxCrudModule {
  static ɵfac = function NgxCrudModule_Factory(t) {
    return new (t || NgxCrudModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxCrudModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCrudModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CommonModule],
      exports: []
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-crud
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Model, NgxCrudModule, NgxCrudService, PageRequest };
