import { Role } from './role.model';

export class User {
  id: number;
  first_name!: string;
  last_name!: string;
  login!: string;
  email!: string;
  password!: string;
  activation_code!: string;
  persist_code!: string;
  reset_password_code!: string;
  is_activated!: boolean;
  last_login!: Date;
  created_at!: Date;
  updated_at!: Date;
  blogger!: string;
  url_google!: string;
  url_facebook!: string;
  url_twitter!: string;
  url_linkedin!: string;
  bio!: string;
  adsense!: boolean;
  author_slug!: string;
  roles!: Role[];
  role!: Role;
  avatar!: string;

  constructor(record?: any) {
    this.id = record?.id;
    record = record || {};
    Object.assign(this, record);

    this.roles = record.roles ? record.roles.map((r: any) => new Role(r)) : [];
    this.role = this.roles.length > 0 ? this.roles[0] : new Role();
  }

  can(permission: string): boolean {
    let permissions: any[] = [];
    if (permission === "livello-3") {
      permissions = ["livello-3", "livello-2", "owners"];
    } else if (permission === 'livello-2') {
      permissions = ['livello-2', 'owners'];
    } else {
      permissions = [permission];
    }
    return this.roles.some((r) => permissions.includes(r.code));
  }

   toString(): string {
    return this.blogger;
  }
}
