import { User } from "./user.model";

export class Categoria {
    id: number;
    color!: string;
    nome!: string;
    descrizione!: string;
    testo!: string;
    // catPadre!: Categoria;
    permalink!: string;
    ordine!: number;
    rubrica!: number;
    autore!: User;
    sort_order!: number;

  constructor(record?: any) {
    this.id = record?.id;
    record = record || {};
    Object.assign(this, record);
    this.autore = record.autore ? new User(record.user) : new User();
  }

   toString(): string {
    return this.nome;
  }
}
