import { Inject, Injectable } from '@angular/core';
import { Categoria } from '@models/categoria.model';
import { NgxCrudService } from '@sinapsys/ngx-crud';
import { Observable, map } from 'rxjs';
import { COMMON_APP_CONFIG } from '../tokens/common-config.token';

@Injectable({
  providedIn: 'root',
})
export class CategorieService extends NgxCrudService<Categoria, number> {
  constructor(@Inject(COMMON_APP_CONFIG) private mainConfig: Required<{ api: { apiBaseUrl: string } }>) {
    super({ apiBaseUrl: '' });
    this._baseUrl += this.mainConfig.api.apiBaseUrl + 'gestione-articoli/categorie/';
  }

  getAutocomplete(sq: string): Observable<Categoria[]> {
    return this.search({ $or: { nome: { $like: sq } } }).pipe(
      map((c) => c.items.map((c) => new Categoria(c)))
    );
  }
}
