import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { mainConfig } from '@config/main.config';
import { Role } from '@models/role.model';
import { AuthService } from '@services/auth.service';
import { RolesService } from '@services/roles.service';
import { UtilsService } from '@services/utils.service';
import { PageRequest } from '@sinapsys/ngx-crud';
import { ColumnDefinition, NgxDynamicTableComponent, NgxDynamicTableModule } from '@sinapsys/ngx-dynamic-table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [NgxDynamicTableModule, mainConfig.material, mainConfig.formModules],
  templateUrl: './roles.component.html',
})
export class RolesComponent implements OnInit {
  @ViewChild(NgxDynamicTableComponent, { static: false }) table!: NgxDynamicTableComponent<Role>;
  @ViewChild('detailsModal', { static: true }) detailsModal!: TemplateRef<unknown>;
  @ViewChild('detailsTemplate', { static: true }) detailsTemplate!: TemplateRef<unknown>;

  columnDefinitions: ColumnDefinition<Role>[] = [];
  searchSubscription?: Subscription;
  searchForm: FormGroup;
  editItemForm: FormGroup;
  editItem?: Role;
  dialogRef?: MatDialogRef<unknown>;
  grantsDataSource: string[] = [];

  constructor(
    public utilsService: UtilsService,
    public datePipe: DatePipe,
    private rolesService: RolesService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    this.searchForm = new FormGroup({
      code: new FormControl<number | undefined>(undefined),
      description: new FormControl<string | undefined>(undefined),
    });
    this.editItemForm = new FormGroup({
      id: new FormControl<number | undefined>(undefined),
      code: new FormControl<string | undefined>(undefined),
      description: new FormControl<string | undefined>(undefined)
    });
  }

  ngOnInit(): void {
    this.columnDefinitions = [
      { id: 'id', header: 'ID', width: '76px', sortable: true },
      { id: 'code', header: 'Codice', sortable: true },
      { id: 'description', header: 'Descrizione', sortable: true },
      { id: '_', header: 'Dettagli', template: this.detailsTemplate, width: '100px' },
    ];
    this.authService.getGrants()?.subscribe({
      next: (res) => {
        this.grantsDataSource = res;
      },
      error: (e) => {
        console.log(e);
      },
    });
  }

  search(keepPage?: boolean) {
    let raw = this.searchForm.getRawValue();
    for (let key in raw) {
      if (raw[key] !== null) {
        raw[key] = { $like: raw[key] };
      }
    }
    if (!keepPage) this.table.paginator.pageIndex = 0;

    let pageRequest: PageRequest = new PageRequest(
      this.table.paginator.pageIndex,
      this.table.paginator.pageSize,
      this.table.sort.direction,
      this.table.sort.active
    );

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    this.searchSubscription = this.rolesService.search(raw, pageRequest).subscribe({
      next: (res) => {
        res.items = res.items.map((item) => new Role(item));
        this.table.populate(res);
      },
      error: (err) => {
        this.utilsService.defaultHttpErrorHandler(err);
      },
    });
  }

  clear() {
    this.searchForm.reset();
    this.table.sort.active = 'id';
    this.table.sort.direction = 'desc';
    this.search();
  }

  new() {
    this.edit(new Role());
  }

  edit(item: Role) {
    this.editItemForm.reset();
    this.editItemForm.patchValue(item);
    this.editItem = item;
    this.dialogRef = this.dialog.open(this.detailsModal, { width: '40%' });
  }

  save() {
    let raw = this.editItemForm.getRawValue();
    this.rolesService.save(raw).subscribe({
      next: (res) => {
        this.utilsService.notificationsService.showNotification('', 'Ruolo salvato con successo', 'success');
        this.editItem = new Role(res);
        this.editItemForm.patchValue(this.editItem);
        this.search(true);
      },
      error: (err) => {
        this.utilsService.defaultHttpErrorHandler(err);
      },
    });
  }

  delete() {
    if (!confirm('Sei sicuro di voler eliminare questo Ruolo?')) return;
    let raw = this.editItemForm.getRawValue();
    this.rolesService.delete(raw.id).subscribe({
      next: (res) => {
        this.utilsService.notificationsService.showNotification('', 'Ruolo eliminato con successo', 'success');
        this.search(true);
        this.dialogRef?.close();
      },
      error: (err) => {
        this.utilsService.defaultHttpErrorHandler(err);
      },
    });
  }
}
