import { Inject, Injectable } from '@angular/core';
import { Provincia } from '@models/provincia.model';
import { NgxCrudService } from '@sinapsys/ngx-crud';
import { Observable, map } from 'rxjs';
import { COMMON_APP_CONFIG } from '../tokens/common-config.token';

@Injectable({
  providedIn: 'root',
})
export class ProvinceService extends NgxCrudService<Provincia, number> {
  constructor(@Inject(COMMON_APP_CONFIG) private mainConfig: Required<{ api: { apiBaseUrl: string } }>) {
    super({ apiBaseUrl: '' });
    this._baseUrl += this.mainConfig.api.apiBaseUrl + 'gestione-articoli/province/';
  }

  getAutocomplete(sq: string): Observable<Provincia[]> {
    return this.search({ $or: { nomeprovincia: { $like: sq } } }).pipe(
      map((c) => c.items.map((c) => new Provincia(c)))
    );
  }
}
