@if(breadcrumbs$().length > 0) {
<div class="breadcrumbs container-fluid container-md pt-2">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      @for(breadcrumb of breadcrumbs$(); track breadcrumb; let last = $last) {
      <ng-container>
        <li class="breadcrumb-item" [ngClass]="{ active: last }" [attr.aria-current]="last ? 'page' : null">
          @if(!last) {
          <a (click)="redirectTo(breadcrumb.url)" tabindex="0">
            {{ breadcrumb.label | translate }}
          </a>
          } @else {
          <span>{{ breadcrumb.label | translate }}</span>
          }
        </li>
      </ng-container>
      }
    </ol>
  </nav>
</div>
}
