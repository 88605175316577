import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { mainConfig } from '@config/main.config';
import { Role } from '@models/role.model';
import { User } from '@models/user.model';
import { RolesService } from '@services/roles.service';
import { UsersService } from '@services/users.service';
import { UtilsService } from '@services/utils.service';
import { PageRequest } from '@sinapsys/ngx-crud';
import { ColumnDefinition, NgxDynamicTableComponent, NgxDynamicTableModule } from '@sinapsys/ngx-dynamic-table';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [NgxDynamicTableModule, mainConfig.material, mainConfig.formModules],
  selector: 'app-users',
  templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
  @ViewChild(NgxDynamicTableComponent, { static: false }) table!: NgxDynamicTableComponent<User>;
  @ViewChild('detailsModal', { static: true }) detailsModal!: TemplateRef<any>;
  @ViewChild('detailsTemplate', { static: true }) detailsTemplate!: TemplateRef<any>;

  columnDefinitions: ColumnDefinition<User>[] = [];

  searchSubscription?: Subscription;
  searchForm: FormGroup;

  editItemForm: FormGroup;
  editItem?: User;

  dialogRef?: MatDialogRef<any>;

  rolesDataSource: Role[] = [];

  constructor(
    public utilsService: UtilsService,
    public datePipe: DatePipe,
    private usersService: UsersService,
    private rolesService: RolesService,
    private dialog: MatDialog
  ) {
    this.searchForm = new FormGroup({
      id: new FormControl<number | undefined>(undefined),
      blogger: new FormControl<string | undefined>(undefined),
      email: new FormControl<string | undefined>(undefined),
    });
    this.editItemForm = new FormGroup({
      id: new FormControl<number | undefined>(undefined),
      blogger: new FormControl<string | undefined>(undefined),
      email: new FormControl<string | undefined>(undefined),
      is_activated: new FormControl<boolean | undefined>(undefined),
      role: new FormControl<Role | undefined>(undefined),
    });
  }

  ngOnInit(): void {
    this.rolesService.search({}, new PageRequest(0, -1)).subscribe((res) => {
      this.rolesDataSource = res.items.map((r: any) => new Role(r));
    });
    this.columnDefinitions = [
      { id: 'id', header: 'ID', width: '76px', sortable: true },
      { id: 'last_login', header: 'Ultimo accesso', parser: (value: any) => value != null ? moment(value).format('DD/MM/YYYY HH:mm'): '-', sortable: true, width: '200px' },
      { id: 'blogger', header: 'Nome e Cognome', sortable: true },
      { id: 'email', header: 'Email', sortable: true },
      { id: 'role', header: 'Ruoli', parser: (value: any) => value.description, sortable: false },
      { id: 'is_activated', header: 'Attivo', parser: (value: any) => (value ? 'Si' : 'No'), width: '76px' },
      { id: '_', header: 'Dettagli', template: this.detailsTemplate, width: '100px' },
    ];
  }

  search(keepPage?: boolean) {
    let raw = this.searchForm.getRawValue();

    for (let key in raw) {
      if (raw[key] !== null) {
        raw[key] = { $like: raw[key] };
      }
    }

    raw.$with = ['program'];

    if (!keepPage) this.table.paginator.pageIndex = 0;

    let pageRequest: PageRequest = new PageRequest(
      this.table.paginator.pageIndex,
      this.table.paginator.pageSize,
      this.table.sort.direction,
      this.table.sort.active
    );

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    this.searchSubscription = this.usersService.search(raw, pageRequest).subscribe((res) => {
      res.items = res.items.map((item) => new User(item));
      this.table.populate(res);
    });
  }

  clear() {
    this.searchForm.reset();
    this.table.sort.active = 'id';
    this.table.sort.direction = 'desc';
    this.search();
  }

  new() {
    this.edit(new User());
  }

  edit(item: User) {
    this.editItemForm.reset();
    this.editItemForm.patchValue(item);
    this.editItem = item;
    this.dialogRef = this.dialog.open(this.detailsModal, { width: '800px' });
  }

  save() {
    let raw = this.editItemForm.getRawValue();
    raw.roles = [raw.role];
    delete raw.role;
    this.usersService.save(raw).subscribe({
      next: (res) => {
        this.utilsService.notificationsService.showNotification('', 'Utente salvato con successo', 'success');
        this.editItem = new User(res);
        this.editItemForm.patchValue(this.editItem);
        this.search(true);
      },
      error: (err) => {
        this.utilsService.defaultHttpErrorHandler(err);
      },
    });
  }

  delete() {
    if (!confirm('Sei sicuro di voler eliminare questo utente?')) return;
    let raw = this.editItemForm.getRawValue();
    this.usersService.delete(raw.id).subscribe({
      next: (res) => {
        this.utilsService.notificationsService.showNotification('', 'Utente eliminato con successo', 'success');
        this.search(true);
        this.dialogRef?.close();
      },
      error: (err) => {
        this.utilsService.defaultHttpErrorHandler(err);
      },
    });
  }
}
