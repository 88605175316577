<div id="toggle-theme" class="pointer-event-none" [ngClass]="(isMobile$| async) ? 'container-fluid' : 'container'">
  <div class="row">
    <div class="switch">
      <input id="switch" class="switch-input" name="switch" type="checkbox" [checked]="isDarkThemeActive()"
        (change)="switchTheme()" [attr.aria-label]="getThemeToggleLabel()" />
      <label class="switch-label pointer-event-auto" for="switch" [matTooltip]="'Modifica il tema, tra chiaro e scuro'">
        @if (!isDarkThemeActive()) {
        <mat-icon class="moon-icon">brightness_2</mat-icon>
        }
        @if (isDarkThemeActive()) {
        <mat-icon class="sun-icon">brightness_5</mat-icon>
        }
      </label>
    </div>
  </div>
</div>
