<div class="h-100" id="sidebar" [ngClass]="{ collapsed: sidebarCollapsed }">
  <app-toggle-theme />
  <div class="toggle-row">
    <img src="/public/images/logo.png" class="logo" alt="Logo" title="Logo">
    <div class="toggle-container pe-2">
      <button type="button" mat-mini-fab color="warn" aria-label="Toggle Sidebar"
        (click)="sidebarCollapsed = !sidebarCollapsed" id="sidebar-toggle">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>
  <div class="menu">
    <div class="menu-module dashboard" [class.active]="isHome()" (click)="openLink('/')" (keydown)="handleKeyDown($event)">
      <div class="menu-module-link">
        <div class="icon" matTooltip="Dashboard" matTooltipPosition="right">
          <mat-icon>dashboard</mat-icon>
        </div>
        <div class="title">Dashboard</div>
      </div>
    </div>
    @for (module of authService.modules(); track module.id) {
      <div class="menu-module" (click)="toggleClass($event,'active')"
        [class.active]="isChildrenPath(module)" (keydown)="handleKeyDown($event)">
        <div class="menu-module-link">
          <div class="icon" [matTooltip]="module.title" matTooltipPosition="right">
            <mat-icon>{{module.icon}}</mat-icon>
          </div>
          <div class="title">{{ module.title }}</div>
        </div>
        <div class="children">
          @for (n of module.navigations; track n.url) {
            <div class="menu-link" [class.active]="isCurrentPath(n)"
              (click)="openLink(n.url)" (keydown)="handleKeyDown($event)">
              <div class="icon" [matTooltip]="n.title" matTooltipPosition="right">
                <mat-icon>{{n.icon}}</mat-icon>
              </div>
              <div class="title">{{ n.title }}</div>
            </div>
          }
        </div>
      </div>
    }
    @if (authService.isAuthenticated()) {
      <div class="menu-module dashboard" (click)="authService.logout()" (keydown)="handleKeyDown($event)">
        <div class="menu-module-link">
          <div class="icon" matTooltip="Logout" matTooltipPosition="right">
            <mat-icon>exit_to_app</mat-icon>
          </div>
          <div class="title">Logout</div>
        </div>
      </div>
    }
  </div>
</div>
