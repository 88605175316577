import { Model } from '@sinapsys/ngx-crud';
import { User } from './user.model';

export class Role {
  id: number;
  name!: string;
  created_at!: Date;
  updated_at!: Date;
  public code!: string;
  public description!: string;
  users!: User[];

  constructor(record?: any) {
    this.id = record?.id;
    record = record || {};
    Object.assign(this, record);
    this.users = record.users ? record.users.map((u: any) => new User(u)) : [];
  }

  
  toString(): string {
    return this.description;
  }
}
