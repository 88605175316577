import { Routes } from '@angular/router';
import { ArticoliComponent } from '@modules/gestione-articoli/articoli/articoli.component';

export const articoliRoutes: Routes = [
  {
    path: 'gestione-articoli/articoli',
    component: ArticoliComponent,
    data: {
      breadcrumb: {
        label: 'Gestione Articoli',
      },
    },
  }
];
