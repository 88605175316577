import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

const requests: HttpRequest<unknown>[] = [];

function removeRequest(req: HttpRequest<unknown>) {
  const i = requests.indexOf(req);
  if (i >= 0) {
    requests.splice(i, 1);
  }
}

function checkLoading() {
  const loading: HTMLElement = document.querySelector('#loading') as HTMLElement;
  if (loading) {
    if (requests.length > 0) {
      loading.classList.remove('hidden');
    } else {
      loading.classList.add('hidden');
    }
  }
}

export const httpInterceptorFn: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  requests.push(req);
  checkLoading();

  return new Observable((observer) => {
    const subscription = next(req).subscribe({
      next: (event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          removeRequest(req);
          checkLoading();
          observer.next(event);
        }
      },
      error: (err: unknown) => {
        removeRequest(req);
        checkLoading();
        observer.error(err);
      },
      complete: () => {
        removeRequest(req);
        checkLoading();
        observer.complete();
      },
    });
    return () => {
      removeRequest(req);
      checkLoading();
      subscription.unsubscribe();
    };
  });
};
