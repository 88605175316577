import { NgClass } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { materialConfig } from '@config/material.config';
import { AuthService, Module, Navigation } from '@services/auth.service';
import { ToggleThemeComponent } from './../../shared/toggle-theme/toggle-theme.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [NgClass, ...materialConfig, ToggleThemeComponent],
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  constructor(public authService: AuthService, private router: Router, private renderer: Renderer2) {}

  ngOnInit() {
    this.sidebarCollapsed = window.innerWidth < 850;
  }

  set sidebarCollapsed(value: boolean) {
    localStorage.setItem('sidebar-collapsed', value ? 'true' : 'false');
  }

  get sidebarCollapsed(): boolean {
    return localStorage.getItem('sidebar-collapsed') === 'true';
  }

  isHome() {
    return this.router.url === '/';
  }

  toggleClass(event: Event, className: string) {
    event.preventDefault();
    event.stopPropagation();
    const target = event.target as HTMLElement;
    const isTarget = target.classList.contains('menu-module');
    if (!isTarget) return;
    const hasClass = target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(target, className);
    } else {
      this.renderer.addClass(target, className);
    }
  }

  isChildrenPath(module: Module) {
    return this.router.url.split('/').filter((a) => !!a)[0] === module.id;
  }

  isCurrentPath(navigation: Navigation) {
    return this.router.url === navigation.url;
  }

  openLink(url: string) {
    if (window.innerWidth < 576) this.sidebarCollapsed = true;
    this.router.navigateByUrl(url);
  }

  handleKeyDown($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      const target = $event.target as HTMLElement;
      target.click();
    }
  }
}
