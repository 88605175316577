import { Inject, Injectable, effect, signal } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarConfig } from '../config/snackbar.config';
import { SnackBarComponent } from '../../shared/snackbar/snackbar.component';
import { COMMON_APP_CONFIG } from '../tokens/common-config.token';

interface Notification {
  config: SnackbarConfig;
  content: {
    header?: string;
    body: string;
    type: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private snackBarRef: MatSnackBarRef<unknown> | null = null;

  snackBarMessage = signal<Notification>({
    config: {
      duration: 0,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: [],
    },
    content: {
      header: '',
      body: '',
      type: '',
    },
  });

  constructor(
    private snackBar: MatSnackBar,
    @Inject(COMMON_APP_CONFIG)
    private mainConfig: Required<{
      snackbar: SnackbarConfig;
    }>
  ) {
    this.snackBarMessage.set({
      config: {
        duration: this.mainConfig.snackbar.duration,
        verticalPosition: this.mainConfig.snackbar.verticalPosition,
        horizontalPosition: this.mainConfig.snackbar.horizontalPosition,
        panelClass: this.mainConfig.snackbar.panelClass || [],
      },
      content: {
        header: '',
        body: '',
        type: '',
      },
    });

    effect(() => {
      const { header, body, type } = this.snackBarMessage().content;
      if (this.snackBarRef) {
        this.snackBarRef.dismiss();
      }
      this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
        duration: this.snackBarMessage().config.duration,
        verticalPosition: this.snackBarMessage().config.verticalPosition,
        horizontalPosition: this.snackBarMessage().config.horizontalPosition,
        panelClass: [`snackbar-${type}`],
      });
    });
  }

  showNotification(header: string | undefined, body: string, type: string): void {
    this.snackBarMessage.set({
      config: {
        duration: this.mainConfig.snackbar.duration,
        verticalPosition: this.mainConfig.snackbar.verticalPosition,
        horizontalPosition: this.mainConfig.snackbar.horizontalPosition,
        panelClass: [`snackbar-${type}`],
      },
      content: {
        header,
        body,
        type,
      },
    });
  }

  hideNotification(): void {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
    }
  }
}
