import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { mainConfig } from '@config/main.config';
import { AuthService } from '@services/auth.service';
import { UsersService } from '@services/users.service';
import { UtilsService } from '@services/utils.service';
import { NgxDynamicTableModule } from '@sinapsys/ngx-dynamic-table';
import { FileUploadModule } from 'primeng/fileupload';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [NgxDynamicTableModule, mainConfig.material, mainConfig.formModules, FileUploadModule],
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {

  profileForm!: FormGroup;

  constructor(
    public utilsService: UtilsService,
    public datePipe: DatePipe,
    private authService: AuthService,
    private usersService: UsersService,
    private snackBar: MatSnackBar
  ) {

  }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      id: new FormControl<number | undefined>(undefined),
      blogger: new FormControl<number | undefined>(undefined),
      email: new FormControl<string | undefined>(undefined),
      url_google: new FormControl<string | undefined>(undefined),
      url_facebook: new FormControl<string | undefined>(undefined),
      url_twitter: new FormControl<string | undefined>(undefined),
      url_linkedin: new FormControl<string | undefined>(undefined),
      avatar: new FormControl<string | undefined>(undefined),
    });
    setTimeout(() => {
      this.profileForm.controls['id'].setValue(this.authService.user?.id);
      this.profileForm.controls['blogger'].setValue(this.authService.user?.blogger);
      this.profileForm.controls['email'].setValue(this.authService.user?.email);
      this.profileForm.controls['email'].disable();
      this.profileForm.controls['url_google'].setValue(this.authService.user?.url_google);
      this.profileForm.controls['url_facebook'].setValue(this.authService.user?.url_facebook);
      this.profileForm.controls['url_twitter'].setValue(this.authService.user?.url_twitter);
      this.profileForm.controls['url_linkedin'].setValue(this.authService.user?.url_linkedin);
      this.profileForm.controls['avatar'].setValue(this.authService.user?.avatar);
      if (this.authService.user) {
        this.imageName.set(this.authService.user?.blogger);
        this.selectedFile = new File([this.authService.user?.avatar], this.authService.user?.blogger + '.jpg', { type: 'image/jpg' });
        this.imagePreview.set(this.authService.user?.avatar);
      }

    }, 1000);
  }

  save(): void {
    let raw = this.profileForm.getRawValue();
    delete raw.email;
    this.usersService.save(raw).subscribe((res) => {
      this.authService._user = res;
      if (this.authService.user) {
        this.imageName.set(this.authService.user.blogger);
        this.selectedFile = new File([this.authService.user.avatar], this.authService.user.blogger + '.jpg', { type: 'image/jpg' });
        this.imagePreview.set(this.authService.user.avatar);
      }
      this.utilsService.notificationsService.showNotification('', 'Profilo salvato con successo', 'success');
    });
  }

  // file
  // file methods
  imageName = signal('');
  fileSize = signal(0);
  uploadProgress = signal(0);
  imagePreview = signal('');
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  selectedFile: File | null = null;
  uploadSuccess: boolean = false;
  uploadError: boolean = false;

  // Handler for file input change
  onFileChange(event: any): void {
    const file = event.target.files[0] as File | null;
    this.uploadFile(file);
  }

  // Handler for file drop
  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] as File | null;
    this.uploadFile(file);
  }

  // Prevent default dragover behavior
  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  // Method to handle file upload
  uploadFile(file: File | null): void {
    if (file && file.type.startsWith('image/')) {
      this.selectedFile = file;
      console.log('file', file);
      this.fileSize.set(Math.round(file.size / 1024)); // Set file size in KB
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64Img = e.target?.result as string;
        this.profileForm.controls['avatar'].setValue(base64Img);
        this.save();
      };
      reader.readAsDataURL(file);
      this.uploadSuccess = true;
      this.uploadError = false;
      this.imageName.set(file.name); // Set image name
    } else {
      this.uploadSuccess = false;
      this.uploadError = true;
      this.snackBar.open('Puoi inserire solo immagini', 'Chiudi', {
        duration: 3000,
        panelClass: 'error',
      });
    }
  }

  // Method to remove the uploaded image
  removeImage(): void {
    this.selectedFile = null;
    this.imageName.set('');
    this.fileSize.set(0);
    this.imagePreview.set('');
    this.uploadSuccess = false;
    this.uploadError = false;
    this.uploadProgress.set(0);
  }

}
