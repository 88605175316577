import Quill from 'quill';
const BlockEmbed: any = Quill.import("blots/block/embed");
const Link: any = Quill.import("formats/link");

export class EmbedVideo extends BlockEmbed {
    static blotName = "embed-video";
    static tagName = "DIV";
    static className = "embed-video";

    input: any;
    frameborder: any;
    allowfullscreen: any;
    allow: any;
    title: any;
    referrerpolicy: any;
    src: any;
    width: any;
    height: any;

    static create(value: any) {
        const node = super.create();
    
        // Check if value is an object containing the necessary properties
        if (typeof value === 'object' && value.src) {
            const child = document.createElement("iframe");
            console.log('keys', Object.keys(value));
            // setAttribute dynamically
            for (let i = 0; i < Object.keys(value).length; i++) {
                const key = Object.keys(value)[i];
                child.setAttribute(key, value[key]);
            }
            
            child.classList.add("embed-video-item");
            node.appendChild(child);
        } else {
            node.innerHTML = '<div class="embed-video"></div>'; // fallback in caso di valore non valido
        }
    
        return node;
    }

    static sanitize(url: any) {
        return Link.sanitize(url);
    }

    static value(domNode: any) {
        const iframe = domNode.querySelector('iframe');
        return this.parseIframeAttributes(iframe.outerHTML);
    }

    static parseIframeAttributes(iframeString: any) {
        const attributes: any = {};
        const regex = /(\w+)=["']([^"']*)["']/g;
        let match;
    
        // Usa l'espressione regolare per trovare tutti gli attributi nell'iframe
        while ((match = regex.exec(iframeString)) !== null) {
            const attributeName = match[1];
            const attributeValue = match[2];
            attributes[attributeName] = attributeValue;
        }
    
        return attributes;
    }
}

Quill.register(EmbedVideo);