import { Injectable, effect, signal } from '@angular/core';

export const storageKey = 'theme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  theme = signal<string>(localStorage.getItem(storageKey) || 'light-theme');
  theme$ = this.theme;
  #body: HTMLBodyElement | null = document.querySelector('body') as HTMLBodyElement;

  constructor() {
    this.initializeThemeFromPreferences();

    effect(() => {
      this.updateRenderedTheme();
    });
  }

  toggleTheme(): void {
    this.theme$.update((prev) => (this.isDarkThemeActive() ? 'light-theme' : 'dark-theme'));
  }

  private initializeThemeFromPreferences(): void {
    const storedTheme = localStorage.getItem(storageKey);
    if (storedTheme) {
      this.theme$.update(() => storedTheme);
    }

    this.changeTheme();
  }

  private changeTheme(): void {
    const c: DOMTokenList = this.#body?.classList!;
    if (!c) return;
    if (c.contains('dark-theme')) {
      c.remove('dark-theme');
      c.add('light-theme');
    } else if (c.contains('light-theme')) {
      c.remove('light-theme');
      c.add('dark-theme');
    }
  }

  getToggleLabel(): string {
    return `Switch to ${this.isDarkThemeActive() ? 'light-theme' : 'dark-theme'} mode`;
  }

  isDarkThemeActive(): boolean {
    return this.theme$() === 'dark-theme';
  }

  private updateRenderedTheme(): void {
    const theme = this.theme$();
    localStorage.setItem(storageKey, theme);
    this.changeTheme();
  }
}
