import { Routes } from '@angular/router';
import { ProfileComponent } from '@modules/settings/profiles/profile.component';
import { RolesComponent } from '@modules/settings/roles/roles.component';
import { UsersComponent } from '@modules/settings/users/users.component';

export const settingsRoutes: Routes = [
  {
    path: 'settings/users',
    component: UsersComponent,
    data: {
      breadcrumb: {
        label: 'Gestione Utenti',
      },
    },
  },
  {
    path: 'settings/roles',
    component: RolesComponent,
    data: {
      breadcrumb: {
        label: 'Gestione Ruoli',
      },
    },
  },
  {
    path: 'settings/profile',
    component: ProfileComponent,
    data: {
      breadcrumb: {
        label: 'Profilo',
      },
    },
  }
];
