import { Routes } from '@angular/router';
import { CategorieComponent } from '@modules/gestione-articoli/categorie/categorie.component';

export const categorieRoutes: Routes = [
  {
    path: 'gestione-articoli/categorie',
    component: CategorieComponent,
    data: {
      breadcrumb: {
        label: 'Gestione Categorie',
      },
    },
  }
];
