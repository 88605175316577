@if (header() || content()) {
<div class="container">
  <div class="row align-items-center justify-content-center">
    <div class="col-2">
      <svg class="icon icon-md icon-white">
        @switch (type()) {
        @case ('success') {
        <use xlink:href="/assets/icons/sprites.svg#it-check-circle"></use>
        }
        @case ('error') {
        <use xlink:href="/assets/icons/sprites.svg#it-error"></use>
        }
        @case ('warn') {
        <use xlink:href="/assets/icons/sprites.svg#it-warning-circle"></use>
        }
        }
      </svg>
    </div>
    <div class="col-8">
      <div matSnackBarLabel
        class="w-100 d-flex flex-column gap-1 align-items-center justify-content-center text-center">
        @if(header()) {
        <span class="fw-semibold">{{ header() }}</span>
        }
        <span class="fw-normal">{{ content() }}</span>
      </div>
    </div>
    <div class="col-2">
      <span matSnackBarActions>
        <button class="btn btn-md" matSnackBarAction (click)="notificationsService.hideNotification()">
          <span class="text-white">X</span>
        </button>
      </span>
    </div>
  </div>
</div>
}