import { Component, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@services/notifications.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent implements OnDestroy {
  redirectTimeout: ReturnType<typeof setTimeout>;
  private router = inject(Router);
  private notificationsService = inject(NotificationsService);

  constructor() {
    this.notificationsService.showNotification('Errore', 'La pagina cercata non esiste', 'error');
    this.redirectTimeout = setTimeout(() => {
      this.router.navigate(['/']);
    }, 5000);
  }

  ngOnDestroy(): void {
    clearTimeout(this.redirectTimeout);
  }
}
