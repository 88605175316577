import { apiConfig } from "./api.config";
import { dateFormat } from "./date-format.config";
import { formModules } from "./form-modules.config";
import { layoutConf } from "./layout";
import { localization } from "./localization.config";
import { materialConfig } from "./material.config";
import { mediaBaseUrl } from "./media-base-url.config";
import { snackbarConfig } from "./snackbar.config";

export const mainConfig = {
  title: 'main.title',
  api: apiConfig,
  localization: localization,
  dateFormat: dateFormat,
  snackbar: snackbarConfig,
  mediaBaseUrl: mediaBaseUrl,
  material: materialConfig,
  formModules: formModules,
  layout: layoutConf,
}
